.radio-btn-music-clip {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.radio-btn-music-clip .radio-option-instance {
  flex: 0 0 auto !important;
  cursor: pointer;
}

.radio-btn-music-clip .radio-buttons-4 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}
