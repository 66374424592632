/* === Wrapper Styles === */
#FileUpload {
  /* display: flex;
  justify-content: center; */
  position: fixed;
  bottom: 0px;
  right: 10px;
  transition: width 0.3s ease-in-out;
}

#FileUpload.minimized {
  width: 200px;
}

.wrapper {
  /* margin: 30px;
  padding: 10px; */
  /* border: 2px solid #1976d2; */
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  /* border-radius: 10px; */
  background-color: white;
  width: 430px;
}

.wrapper h6 {
  padding-left: 20px;
  padding-top: 5px;
}

/* === Upload Box === */
.upload {
  /* margin: 10px; */
  height: 50px;
  /* border: 6px solid #1976d2; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Poppins';
}

.upload p {
  margin-right: 20px;
  line-height: 35;
  font-size: 16px;
  font-family: 'Poppins';
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* === Minimized Mode === */
.minimized-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: 2px solid #1976d2;
  box-shadow: 0 5px 10px rgba(0,0,0,0.15);
  border-radius: 5px;
  background-color: #4a3aff;
  width: 200px;
}

/* === Header Buttons === */
.header-buttons {
  float: right;
  display: inline;
}

.upload-header {
    background-color: #4a3aff;
    color: white;
    opacity: 0.9;
    border-radius: 8px 8px 0px 0px;
    font-size: 15px;
}

.header-buttons button {
  margin-left: 50px;
}

/* === Uploaded Files === */
.uploaded {
  width: 375px;
  margin: 10px auto;
  background-color: #796fed;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.file {
  display: flex;
  flex-direction: column;
}

.file__name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 300px;
  line-height: 0;
  color: white;
  font-size: 18px;
  font-family: 'Poppins';
  letter-spacing: 1.5px;
}

.fa-times:hover {
  cursor: pointer;
  opacity: 0.8;
}

.fa-file-pdf {
  padding: 15px;
  font-size: 40px;
  color: black;
}
