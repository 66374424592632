.checkbox {
  background-size: 100% 100%;
  height: 24px;
  width: 24px;
}

.checkbox.fi-rr-checkbox {
  background-image: url(../../../static/img/fi-rr-checkbox-3.svg);
}

.checkbox.fi-sr-checkbox {
  background-image: url(../../../static/img/fi-sr-checkbox-3.svg);
}

.checkbox.fi-sr-info {
  background-image: url(../../../static/img/fi-sr-info.svg);
}
