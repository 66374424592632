.create-exit-clip-frame {
  background-color: #f7f7fb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 1280px;
}

.create-exit-clip-frame .div-3 {
  background-color: #f7f7fb;
  height: 1024px;
  position: relative;
  width: 100%;
}

.create-exit-clip-frame .overlap {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.create-exit-clip-frame .rectangle {
  background-color: #ffffff;
  box-shadow: 0px 4px 24px #0000000d;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* .create-exit-clip-frame .header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
} */

.create-exit-clip-frame .icon-instance-node {
 height: 24px !important;
  position: absolute !important;
  width: 24px !important;
  right: 0px;
}

.create-exit-clip-frame .frame-12 {
  /* align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 304px;
  position: absolute;
  top: 109px;
  width: 1112px; */
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 324px;
  width: 73%;
  position: absolute;
  top: 109px;
}

.create-exit-clip-frame .frame-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.create-exit-clip-frame .section-title {
  /* align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 1072px; */
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.create-exit-clip-frame .frame-14 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 636px;
  
}

.create-exit-clip-frame .page-title {
  /* align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative; */
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.create-exit-clip-frame .text-wrapper-4 {
  /* color: var(--primary-text);
  font-family: "Nunito Sans", Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content; */
  color: var(--primary-text);
  font-family: 'Nunito Sans-Bold', Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create-exit-clip-frame .breadcrum {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.create-exit-clip-frame .material-symbols {
  height: 20px;
  position: relative;
  width: 20px;
}

.create-exit-clip-frame .keyboard-arrow-right {
  height: 8px;
  position: relative;
  width: 5.33px;
}

.create-exit-clip-frame .text-wrapper-5 {
  color: #c4c4c4;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-frame .text-wrapper-6 {
  color: #4a3aff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-frame .frame-15 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.create-exit-clip-frame .stepper-horizontal-instance {
  flex: 0 0 auto !important;
  margin-left: -6px !important;
  margin-right: -6px !important;
}

.create-exit-clip-frame .frame-16 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px 56px;
  width: 100%;
  position: relative;
}

.create-exit-clip-frame .frame-16-1 {
  
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
 
}

.create-exit-clip-frame .frame-17 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.create-exit-clip-frame .frame-18 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.create-exit-clip-frame .frame-19 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.create-exit-clip-frame .frame-20x {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  position: relative;

}

.create-exit-clip-frame .frame-21 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 864px;
}

.create-exit-clip-frame .frame-22 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}


.create-exit-clip-frame .frame-23 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  gap:10px;
}

.create-exit-clip-frame .frame-24 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.create-exit-clip-frame .text-wrapper-7 {
  color: var(--neutral-800);
  font-family: var(--headings-typography-size-3-font-family);
  font-size: var(--headings-typography-size-3-font-size);
  font-style: var(--headings-typography-size-3-font-style);
  font-weight: var(--headings-typography-size-3-font-weight);
  letter-spacing: var(--headings-typography-size-3-letter-spacing);
  line-height: var(--headings-typography-size-3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create-exit-clip-frame .frame-25 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

/* .create-exit-clip-frame .frame-26 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
} */

.create-exit-clip-frame .template-empty-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.create-exit-clip-frame .video-tutorial-how {
  color: transparent;
  font-family: "Raleway", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-frame .span {
  color: #282828;
}

.create-exit-clip-frame .text-wrapper-8 {
  color: #4a3aff;
  text-decoration: underline;
  cursor: pointer;
}

.create-exit-clip-frame .img {
  height: 468px;
  position: relative;
  width: 864px;
}

.create-exit-clip-frame .fi-rr-edit-wrapper {
  align-items: flex-start;
  background-color: #000000;
  display: inline-flex;
  gap: 10px;
  left: 825px;
  padding: 8px 6px;
  position: absolute;
  top: 68px;
}

.create-exit-clip-frame .fi-rr-edit {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.create-exit-clip-frame .frame-27 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
  background-color: transparent;
}

.create-exit-clip-frame .primary-BTN {
  /* all: unset; */
  align-items: center;
  border: 2px solid;
  border-color: #a0a3bd;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
margin-left: 75%;
cursor: pointer;
}

.create-exit-clip-frame .button-text {
  all: unset;
  box-sizing: border-box;
  color: #a0a3bd;
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.button-wrap {
  display: flex;
  gap: 10px;
}
.create-exit-clip-frame .button-text-wrapper {
  all: unset;
  align-items: center;
  background-color: var(--primarycolor-1);
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.button {
  all: unset;
  box-sizing: border-box;
  color: var(--neutral-100);
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-left: 0.5%;
}

.create-exit-clip-frame .primary-BTN {
  all: unset;
  align-items: center;
  border: 2px solid;
  border-color: #a0a3bd;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.create-exit-clip-frame .left-nav-instance {
  left: 0 !important;
  position: absolute !important;
  top: 85px !important;
  height: 1200px;
}

.create-exit-clip-frame .left-nav-3 {
  left: unset !important;
}

.create-exit-clip-frame .exit-clip-root {
  
  border-radius: 16px;
  background-color: rgb(0, 0, 0);
  border: solid 1px;
  padding: 175px;
  color: #fff;
  height: 480px;
  width: 880px;
  position: relative;
}

.create-exit-clip-frame .exit-clip-root .exit-clip-upload-logo {
      width: 750px;
      height: 290px;
      /*top: 151px;*/
      /*left: 246.5px;*/
      border: dotted 1px #fff;
      margin-top: -318px;
  }
.create-exit-clip-frame .empty-logo-text{
  font-family: "DM Sans", Helvetica;
  margin-top: 10px;
}
  .exit-clip-root .parent {
      display: flex;
      justify-content: center; /* horizontally center */
      align-items: center; /* vertically center */
      height: 453px; /* adjust height as needed */
      /*border: 1px solid black;*/ /* for visualization */
  }

  .exit-clip-root .logo-text {
      width: 750px;
      /* height: 64px; */
      border: 1px dotted #fff;
      top: 404px;
      left: 245px;
      color: #fff;
      text-align: center;
      margin-top: 5px;
  }

      .exit-clip-root .logo-text .edit-icon {
        right: -97%;
          background-color: black;
          top: -1px !important;
      }

      .exit-clip-root .logo-text .edit-icon, .exit-clip-upload-logo .upload-icon {
          position: relative;
          color: #fff;
          top: -8px;
          width:24px;
      }

.exit-clip-upload-logo .upload-icon {
  right: -97%;
  background-color: black;
  padding: 5px;
}

.exit-clip-upload-logo img {
  max-width: 100%; /* Ensure image doesn't exceed container width */
  max-height: 100%; /* Ensure image doesn't exceed container height */
  object-fit: contain; /* Maintain aspect ratio and fit within container */
}

.exit-clip-root .child {
  /* Additional styling for the child */
  position: relative;
}

.exit-clip-root .logo-container {
  width: 100px;
  height: 100px;
  border-radius: 50%; /* makes the container circular */
  /*overflow: hidden;*/ /* clips the image to the circular shape */
  border: 2px solid #000; /* border for visualization, you can remove this */
  top: 100px;
  color: #fff;
  cursor: pointer;
  margin-top: -150px;
  margin-left:14px;
}

.exit-clip-root .logo {
  width: 100%; /* make the image fill the circular container */
  height: auto; /* maintain aspect ratio */
}

.edit-icon-background{
  top: -10px;
  position: absolute;
  right: 10px;
  background-color: black;
  padding: 5px;
}

.back-text-btn {
color: white;
}