.create-intro-clip-frame {
  background-color: #f7f7fb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 1280px;
}

.create-intro-clip-frame .div-3 {
  background-color: #f7f7fb;
  height: 1024px;
  position: relative;
  width: 100%;
}

.create-intro-clip-frame .overlap {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.create-intro-clip-frame .rectangle {
  background-color: #ffffff;
  box-shadow: 0px 4px 24px #0000000d;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.create-intro-clip-frame .header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.create-intro-clip-frame .icon-instance-node {
  height: 24px !important;
  position: absolute !important;
  width: 24px !important;
  right:0px;
}

.create-intro-clip-frame .icon-instance-node-upload {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
  /* right:0px; */
}

.create-intro-clip-frame .frame-12 {
  /* align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  height: 884px;
  left: 304px;
  overflow-y: scroll;
  position: absolute;
  top: 109px; */
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 324px;
  width: 73%;
  position: absolute;
  top: 109px;
}

.create-intro-clip-frame .frame-13 {
  /* align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;*/
  left: 0; 
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
  top: 0;
}

.create-intro-clip-frame .section-title {
  /* align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 1072px;
  left: 0px; */
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative
}

.create-intro-clip-frame .frame-14 {
  /* align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 636px;
   */
   align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 636px;
}

.create-intro-clip-frame .page-title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.create-intro-clip-frame .text-wrapper-4 {
  /* color: var(--primary-text);
  font-family: "Nunito Sans", Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content; */
  color: var(--primary-text);
  font-family: 'Nunito Sans-Bold', Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create-intro-clip-frame .breadcrum {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.create-intro-clip-frame .material-symbols {
  height: 20px;
  position: relative;
  width: 20px;
}

.create-intro-clip-frame .keyboard-arrow-right {
  height: 8px;
  position: relative;
  width: 5.33px;
}

.create-intro-clip-frame .text-wrapper-5 {
  color: #c4c4c4;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-intro-clip-frame .text-wrapper-6 {
  color: #4a3aff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-intro-clip-frame .frame-15 {
  /* align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px 72px;
  position: relative;
  width: 100%; */
  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  /* padding: 32px 56px; */
  width: 100%;
  position: relative;
}

.create-intro-clip-frame .design-component-instance-node-2 {
  flex: 0 0 auto !important;
}

.create-intro-clip-frame .frame-16 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px 56px;
  width: 100%;
  position: relative;
}
.create-intro-clip-frame .frame-16-1 {
   align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.create-intro-clip-frame .frame-17 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 64px;
  position: relative;
  width: 100%;
}

.create-intro-clip-frame .frame-18 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.create-intro-clip-frame .frame-19 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.create-intro-clip-frame .frame-20 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.create-intro-clip-frame .frame-21 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.create-intro-clip-frame .text-wrapper-7 {
  color: var(--neutral-800);
  font-family: var(--headings-typography-size-3-font-family);
  font-size: var(--headings-typography-size-3-font-size);
  font-style: var(--headings-typography-size-3-font-style);
  font-weight: var(--headings-typography-size-3-font-weight);
  letter-spacing: var(--headings-typography-size-3-letter-spacing);
  line-height: var(--headings-typography-size-3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create-intro-clip-frame .frame-22 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.create-intro-clip-frame .frame-23 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  gap:10px;
}

.create-intro-clip-frame .component-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.create-intro-clip-frame .component-1 {
  background-image: url(../../../../static/img/vuesax-linear-teacher.svg) !important;
}

.create-intro-clip-frame .video-tutorial-how {
  color: transparent;
  font-family: "Raleway", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  
}

.create-intro-clip-frame .span {
  color: #282828;
}

.create-intro-clip-frame .text-wrapper-8 {
  color: #4a3aff;
  text-decoration: underline;
  cursor: pointer;
}

.create-intro-clip-frame .group-2 {
   background-image: none;
  background-position: 50% 50%;
  background-size: cover;
  height: 480px;
  position: relative;
  width: 880px;
  border-radius: 20px;
}

.create-intro-clip-frame .overlap-group-wrapper {
  height: 444px;
  left: 34px;
  position: absolute;
  top: 26px;
  width: 385px;
}

.create-intro-clip-frame .group-3 {
  background-image: none;
  background-size: 100% 100%;
  height: 375px;
  left: 20px;
  position: absolute;
  top: 50px;
  width: 375px;
  border: 10px double;
  text-align: center;
}

.create-intro-clip-frame .frame-24 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  height: 73px;
  opacity: 0.5;
  position: relative;
  top: 160px;
  cursor: pointer;
}

.create-intro-clip-frame .text-wrapper-9 {
  color: #717171;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-intro-clip-frame .fi-rr-edit-12-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  justify-content: end;
  padding: 8px;
  position: absolute;
  top: -20px;
  right: -20px;
}

.create-intro-clip-frame .icon-instance-node-2 {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.create-intro-clip-frame .frame-25 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  height: 216px;
  left: 420px;
  position: absolute;
  top: 100px;
}

.create-intro-clip-frame .frame-26 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.create-intro-clip-frame .frame-27-class {
  align-items: center;
  border: 1px dashed;
  border-color: #89939e;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 8px;
  position: relative;
}

.create-intro-clip-frame .frame-27-year {
  align-items: center;
  border: 1px dashed;
  border-color: #89939e;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 8px;
  position: relative;
}
.create-intro-clip-frame .text-wrapper-10 {
  border: none;
  outline: none;
  background: none;
  color: #413e3e;
  font-family: "HeadLineA-Regular" ;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-intro-clip-frame .fi-rr-edit-14-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  justify-content: end;
  /* left: 140px; */
  padding: 8px;
  position: absolute;
  top: -13px;
  right: -12px;
}

.create-intro-clip-frame .text-wrapper-11 {
  /* color: #6e6e6e; */
  border: none;
  outline: none;
  background: none;
  font-family: "Fraunces";
  font-size: 42px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 44px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  height: 50px;
}

.create-intro-clip-frame .fi-rr-edit-15-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  justify-content: end;
  /* left: 204px; */
  padding: 8px;
  position: absolute;
  top: -13px;
  right: -12px;
}

.create-intro-clip-frame .overlap-2 {
  height: 80px;
  position: relative;
  width: 370px;
  margin-top: 30px;
}

.create-intro-clip-frame .rectangle-2 {
  border: 1px dashed;
  border-color: #89939e;
  height: 64px;
  left: 0;
  position: absolute;
  width: 370px;
}

.create-intro-clip-frame .frame-28 {
  align-items: center;
  display: inline-flex;
  gap: 24px;
  left: 74px;
  position: absolute;
  top: 5px;
  flex-direction: row;
  cursor: pointer;
  overflow: hidden; /* Ensure content doesn't overflow */
}

.create-intro-clip-frame .frame-28 img {
  max-width: 100%; /* Ensure image doesn't exceed container width */
  max-height: 100%; /* Ensure image doesn't exceed container height */
  object-fit: contain; /* Maintain aspect ratio and fit within container */
}

.create-intro-clip-frame .group-4 {
  height: 55px;
  position: relative;
  width: 57px;
}

.create-intro-clip-frame .overlap-group-3 {
  background-color: #6e6e6e;
  border-radius: 27.5px;
  height: 55px;
  position: relative;
  width: 55px;
}

.create-intro-clip-frame .text-wrapper-12 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 0;
  line-height: 44px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
}

.create-intro-clip-frame .text-wrapper-13 {
  color: #000000;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-intro-clip-frame .fi-rr-cloud-upload-wrapper {
  align-items: flex-start;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  left: 352px;
  padding: 10px;
  position: absolute;
  top: -20px;
}

.create-intro-clip-frame .fi-rr-edit-11-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 8px;
  position: absolute;
  top: -10px;
    right: 0px;
}

.create-intro-clip-frame .template-empty {
  height: 24px !important;
  left: 0px !important;
  position: absolute !important;
  top: -3px !important;
  width: 24px !important;
}

.create-intro-clip-frame .frame-29 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
  border:unset;
}

.create-intro-clip-frame .primary-BTN {
  all: unset;
  align-items: center;
  border: 2px solid;
  border-color: #a0a3bd;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.create-intro-clip-frame .button-text {
  all: unset;
  box-sizing: border-box;
  color: #a0a3bd;
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-intro-clip-frame .button-text-wrapper {
  all: unset;
  align-items: center;
  background-color: var(--primarycolor-1);
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.create-intro-clip-frame .button {
  all: unset;
  box-sizing: border-box;
  color: var(--neutral-100);
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-intro-clip-frame .left-nav-instance {
  left: 0 !important;
  position: absolute !important;
  top: 85px !important;
  height: 1200px;
}

.create-intro-clip-frame .left-nav-3 {
  left: unset !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  left: 8px;
  padding: 24px 0px 0px;
  position: relative;
  top: 12px;
  width: 280px;
}

/* Modal Images Css */

.uploadModal {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.uploadModal .div-2 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 6px 16px #0000000f;
  height: 620px;
  overflow: hidden;
  position: relative;
  width: 540px;
}

.uploadModal .CTA {
  all: unset;
  align-items: center;
  background-color: #4a3aff;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  left: 47px;
  opacity: 0.5;
  padding: 9px 14px;
  position: absolute;
  top: 545px;
  width: 445px;
}

.uploadModal .label {
  color: #ffffff;
  font-family: "Mulish", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.uploadModal .heading-upload {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  left: 42px;
  padding: 0px 10px;
  position: absolute;
  top: 35px;
}

.uploadModal .text-wrapper {
  color: #0e0e0e;
  font-family: "Mulish", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.uploadModal .upload-file-area {
  background-color: #f8f7ff;
  border: 1px dashed;
  border-color: #4a3aff;
  border-radius: 4px;
  height: 427px;
  left: 47px;
  position: absolute;
  top: 88px;
  width: 445px;
  cursor: pointer;
}

.uploadModal .frame-2 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 28px;
  left: 62px;
  position: relative;
  top: 142px;
}

.uploadModal .upload-icon {
  height: 59.89px;
  position: relative;
  width: 68.78px;
}

.uploadModal .frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.uploadModal .drag-drop-files {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 5px;
  position: relative;
}

.uploadModal .drag-drop-files-or {
  color: transparent;
  font-family: "Mulish", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.uploadModal .span {
  color: #0e0e0e;
}

.uploadModal .text-wrapper-2 {
  color: #4a3aff;
  text-decoration: underline;
}

.uploadModal .text-wrapper-3 {
  color: #4a3aff;
  text-decoration: underline;
}

.uploadModal .frame-1027 {
  flex: 0 0 auto !important;
  padding: 0px 0px 0px 6px !important;
}

.create-intro-clip-frame .plusIcon_Image{
  width: 64px;
  height: 64px;
  padding: 12px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  align-items: center;
  display: flex;
  border: 1px solid #F0F0F0;
  justify-content: center;
  cursor: pointer;
}

.create-intro-clip-frame .plusIcon_ImagePreview{
  width: 64px;
  height: 64px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;
  border: 1px solid #F0F0F0;
}

.create-intro-clip-frame .delete-button{
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

