.frame-wrapper-template {
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  gap: 10px;
  position: relative;
}

.frame-wrapper-template .frame-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.frame-wrapper-template .i-authorise {
  color: #1c1b1f;
  font-family: "Poppins", Helvetica;
  font-size: var(--typo-overline-font-size);
  font-style: var(--typo-overline-font-style);
  font-weight: var(--typo-overline-font-weight);
  letter-spacing: var(--typo-overline-letter-spacing);
  line-height: var(--typo-overline-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
