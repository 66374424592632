.group {
  height: 108px;
  width: 420px;
}

.group .frame-14 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.group .event-ID-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.group .event-ID {
  color: var(--neutral-800);
  font-family: var(--other-18-20-m-font-family);
  font-size: var(--other-18-20-m-font-size);
  font-style: var(--other-18-20-m-font-style);
  font-weight: var(--other-18-20-m-font-weight);
  letter-spacing: var(--other-18-20-m-letter-spacing);
  line-height: var(--other-18-20-m-line-height);
  margin-top: -1px;
  position: relative;
  width: 69.93px;
}

.group .frame-15 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.group .frame-16 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #eff0f6;
  border-radius: 8px;
  box-shadow: 0px 2px 6px #13124212;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 16px;
  position: relative;
}

.group .frame-17 {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 362px;
}

.group .element-2 {
  color: var(--neutral-600);
  font-family: var(--other-18-20-r-font-family);
  font-size: var(--other-18-20-r-font-size);
  font-style: var(--other-18-20-r-font-style);
  font-weight: var(--other-18-20-r-font-weight);
  letter-spacing: var(--other-18-20-r-letter-spacing);
  line-height: var(--other-18-20-r-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.group .frame-1027-instance {
  flex: 0 0 auto !important;
  padding: 0px 0px 0px 6px !important;
}
