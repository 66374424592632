.property-group {
  height: 200px;
  transform: rotate(90deg);
  width: 200px;
}

.property-group .overlap-group {
  height: 200px;
  position: relative;
}

.property-group .ellipse {
  height: 200px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-90deg);
  width: 200px;
}

.property-group .fi-rr-check {
  height: 56px !important;
  left: 72px !important;
  position: absolute !important;
  top: 72px !important;
  transform: rotate(-90deg) !important;
  width: 56px !important;
}
