.pending-video-dashboard {
  background-color: #f7f7fb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 1280px;
}

.pending-video-dashboard .div-2 {
  background-color: #f7f7fb;
  height: 1024px;
  position: relative;
  width: 100%;
}

.pending-video-dashboard .overlap-2 {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.pending-video-dashboard .rectangle-2 {
  background-color: #ffffff;
  box-shadow: 0px 4px 24px #0000000d;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.pending-video-dashboard .header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.pending-video-dashboard .left-nav-instance {
  left: 0 !important;
  position: absolute !important;
  top: 85px !important;
  min-height: 1200px;
}



.pending-video-dashboard .left-nav-3 {
  left: unset !important;
}

.pending-video-dashboard .frame-19 {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 324px;
  position: absolute;
  top: 105px;
  width: 73%;
}

.pending-video-dashboard .frame-20 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.pending-video-dashboard .section-title {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.pending-video-dashboard .frame-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 636px;
}

.pending-video-dashboard .page-title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.pending-video-dashboard .pagetitle {
  color: var(--primary-text);
  font-family: 'Poppins', Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.pending-video-dashboard .breadcrum {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.pending-video-dashboard .material-symbols {
  height: 20px;
  position: relative;
  width: 20px;
}

.pending-video-dashboard .keyboard-arrow-right {
  height: 8px;
  position: relative;
  width: 5.33px;
}

.pending-video-dashboard .text-wrapper-4 {
  color: #c4c4c4;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.pending-video-dashboard .text-wrapper-5 {
  color: #4a3aff;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.pending-video-dashboard .frame-data {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
  height: 824px;
}

.pending-video-dashboard .frame-23 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.pending-video-dashboard .frame-1003-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 112px;
  position: relative;
}

.pending-video-dashboard .frame-1003-instance {
  flex: 0 0 auto !important;
}

.pending-video-dashboard .frame-24 {
  background-color: #d02b2f33 !important;
}

.pending-video-dashboard .frame-25 {
  background-color: #d02b2f !important;
}

.pending-video-dashboard .frame-26 {
  color: #d02b2f !important;
}

.pending-video-dashboard .line {
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 928px;
}

.pending-video-dashboard .frame-27 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.pending-video-dashboard .frame-28 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.pending-video-dashboard .frame-29 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  position: relative;
  width: 682.51px;
}

.pending-video-dashboard .text-wrapper-6 {
  color: var(--neutral-800);
  font-family: var(--headings-typography-size-3-font-family);
  font-size: var(--headings-typography-size-3-font-size);
  font-style: var(--headings-typography-size-3-font-style);
  font-weight: var(--headings-typography-size-3-font-weight);
  letter-spacing: var(--headings-typography-size-3-letter-spacing);
  line-height: var(--headings-typography-size-3-line-height);
  margin-top: -1px;
  position: relative;
  width: 202.63px;
}

.pending-video-dashboard .frame-30 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.pending-video-dashboard .frame-31 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.pending-video-dashboard .group-37146 {
  position: relative !important;
}

.pending-video-dashboard .group-instance {
  left: 13px !important;
  top: 14px !important;
}

.pending-video-dashboard .group-37146-instance {
  color: #c8c8c8 !important;
  font-family: "Raleway", Helvetica !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.pending-video-dashboard .group-2 {
  white-space: nowrap !important;
  width: fit-content !important;
}

.pending-video-dashboard .group-3 {
  top: 14px !important;
}

.pending-video-dashboard .primary-BTN-wrapper {
  all: unset;
  align-items: flex-start;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
}

.pending-video-dashboard .primary-BTN {
  all: unset;
  align-items: center;
  background-color: var(--primarycolor-1);
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
}

.pending-video-dashboard .button-text {
  all: unset;
  box-sizing: border-box;
  color: var(--neutral-100);
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.pending-video-dashboard .frame-datasearch {
  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  height: 56px;
  /* left: 0;
  position: absolute;
  top: 0; */
  display: flex;
  width: 100%;
  justify-content: space-between;
  
}

.pending-video-dashboard .frame-datasearch2 {
  height: 40px;
  left: 16px;
  position: relative;
  top: 8px;
  width: 50%;
}

.pending-video-dashboard .frame-datafilter {
  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  height: 56px;
  left: 0;
  position: relative;
  top: 0;
  width: 50%;
}

.pending-video-dashboard .search {
  background-color: #ffffff;
  border-radius: 4px;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 320px;
  border: 1px solid var(--Light-Beerus-Beerus, #EBEBEB);
  
}

.pending-video-dashboard .label-and {
  color: #999ca0;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  width: 272px;
}


.pending-video-dashboard .input {
  background: transparent;
  border: none;
  color: #999ca0;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0;
  padding-left: 8px;
  height: 40px;
  width:272px;
}

.pending-video-dashboard .generic {
  height: 24px;
  left: 288px;
  position: absolute;
  top: 8px;
  width: 24px;
}

.pending-video-dashboard .filter-icon {
  height: 32px;
  left: 336px;
  position: absolute;
  top: 4px;
  width: 32px;
}

.pending-video-dashboard .frame-datagrid {
  height: 768px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 100%;
}

/* ----------------------- */
.video-review-stats {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
  margin-top: 1rem;
}

.video-review-stats__item {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 200px;
  height: 85px;
}

.video-review-stats__label {
  
  font-family: Poppins;
font-size: 14px;
font-weight: 500;
letter-spacing: 0px;
text-align: left;
color: #809FB8;
}

.video-review-stats__value {
  font-family: Poppins;
font-size: 21px;
font-weight: 700;
letter-spacing: 0px;
text-align: left;
color: #4A3AFF;
}

.video-review-approved {
  color: #1BA421;
  font-family: Poppins;
font-size: 21px;
font-weight: 700;
letter-spacing: 0px;
text-align: left;
}

.video-review-pending {
  color: #FB813C;
  font-family: Poppins;
font-size: 21px;
font-weight: 700;
letter-spacing: 0px;
text-align: left;
}

.video-review-rejected {
  color: #EA0C0C;
  font-family: Poppins;
font-size: 21px;
font-weight: 700;
letter-spacing: 0px;
text-align: left;
}

.accept-button {
  background-color: #4CAF50;
  border-radius: 7px;
  border:0px;
  color: white;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  height: 40px;
  font-family: 'Poppins', Helvetica;
  width: 90px;
}

.reject-button {
  background-color: #EA0C0C;
  /* Red */
  border-radius: 7px;
  border:0px;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 20px 10px 10px;
  cursor: pointer;
  height: 40px;
  width: 90px;
  font-family: 'Poppins', Helvetica;
}

.text-area {
  width: 300px;
  height: 150px;
}

.button-modal {
  border-radius: 20px;
  width: 82px;
  height: 35px;
  font-family: 'Poppins', Helvetica;
  cursor: pointer;
}

.rejectyes{
  background-color: #4A3AFF;
  color: white;
  border:0px;
}
.rejectno{
  border-color: #4A3AFF;
  color: #4A3AFF;
  background-color: white;
}

.modal-label {
  color: grey;
  font-family: 'Poppins', Helvetica;
}
.modal-label-reject{
  color:black;
  font-family: 'Poppins', Helvetica;

}


.dialogConfig .MuiDialog-paper{
  height: 300px;
  width: 500px;
}
.dialogConfig2 .MuiDialog-paper{
  height: 200px;
  width: 450px;
}
.rejectDialogConfig .MuiDialog-paper{
  height: 420px;
  width: 500px;
}

.brightness-alert {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ellipse1{
  background-color: transparent;
  border-radius: 27.5px;
    height: 25px;
    left: 0;
    position: absolute;
    top: 21px;
    width: 55px;
}
.overlap-group1{
  position: relative;
}
.fi-rr-check{
  height:18px;
  padding-top: 6px;
  margin-top: 20px;
}