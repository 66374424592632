.standard-btn {
  align-items: center;
  border: 1px solid;
  border-color: #4a3aff;
  border-radius: 4px;
  box-shadow: 0px 2px 6px #13124212;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
  width: 180px;
  cursor: pointer;
}

.standard-btn .standard-gradreel {
  color: #4a3aff;
  font-family: "Raleway", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.standard-btn.default {
  background-color: #ffffff;
}

.standard-btn.variant-3 {
  background-color: #3422fd;
}

.standard-btn.variant-4 {
  background-color: #ffffff;
}

.standard-btn:hover {
  background-color: #4a3aff;
}

.standard-btn:hover .standard-gradreel {
  color: #ffffff;
  font-weight: 600;
}
.standard-btn:active {
  background-color: #4a3aff;
}

.standard-btn:active .standard-gradreel {
  color: #ffffff;
  font-weight: 600;
}

.standard-btn.selected {
  background-color: #4a3aff; /* Change the color for the selected state */
}

.standard-btn.selected .standard-gradreel {
  color: #ffffff;
  font-weight: 600;
}