.home {
  background-color: #4a3aff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #4a3aff1a;
  border-radius: 8px;
  height: 44px;
  overflow: hidden;
  width: 264px;
}

.home .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
  height: 44px;
  position: relative;
}

.home .frame-8 {
  align-items: center;
  display: flex;
  gap: 8px;
  height: 44px;
  padding: 10px 8px;
  position: relative;
  width: 264px;
}

.home .frame-9 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 4px;
  margin-bottom: -4px;
  margin-top: -4px;
  position: relative;
}

.home .frame-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 4px;
  position: relative;
}

.home .fi-rr-graduation-cap {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.home .event-overview-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  padding: 1px 4px;
  position: relative;
}

.home .event-overview-2 {
  color: #ffffff;
  font-family: "Raleway";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .element-wrapper {
  align-items: center;
  background-color: var(--colorslight-blue50);
  border: 1px solid;
  border-color: #0094ff;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  overflow: hidden;
  padding: 4px 8px;
  position: relative;
}

.home .element {
  color: #0094ff;
  font-family: "Open Sans", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.home .dd-items {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -124px;
  padding: 0px 28px;
  position: relative;
  width: 264px;
}

.home .subitem-instance {
  display: inline-flex !important;
  height: 30px !important;
  padding: 0px 10px 0px 0px !important;
  width: unset !important;
}

.home .design-component-instance-node {
  color: #374957 !important;
  margin-top: -1px !important;
}
