.header {
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: 0px 4px 32px #0000000d;
  display: flex;
  gap: 165px;
  padding: 8px 24px;
  position: relative;
  width: 100%;
}

.header .div {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 32px;
  position: relative;
  
}

.header .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 49px;
  position: relative;
}

.header .frame-1 {
  flex: 0 0 auto !important;
}

.header .menu {
  height: 32px;
  position: relative;
  width: 32px;
}

.header .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.header .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.header .text-wrapper-2 {
  color: #000000;
  font-family: "Raleway", Helvetica;
  font-size: 21px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.header .p {
  color: #717171;
  font-family: "Raleway", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.header .frame-5 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.header .schedule {
  height: 48px;
  position: relative;
  width: 24px;
}

.header .overlap {
  height: 52px;
  position: relative;
}

.header .element-bell {
  height: 24px;
  left: 0;
  position: absolute;
  top: 20px;
  width: 26px;
}

.header .notification-warning-instance {
  left: 16px !important;
  position: absolute !important;
  top: 0 !important;
}

.header .frame-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.header .ellipse {
  height: 48px;
  position: relative;
  width: 48px;
}

.header .icon-chevron-down {
  height: 10.13px;
  position: relative;
  width: 18px;
}
.frame-wrapper .text-wrapper {
  color: var(--greysblue-grey900, #151D48);
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-wrapper .text-wrapper-2 {
  color: #A1A6C1;
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-3 {
  color: var(--primary-text);
  font-family: 'Nunito Sans-Bold', Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.no-BTN {
  align-items: center;
  background-color: var(--primarycolor-1);
  border: 1px solid;
  border-color: #89939e;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 8px 32px;
  position: relative;
}
.yes-BTN {
  align-items: center;
  background-color: #4a3aff;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 8px 32px;
  position: relative;
}
.frame-model .button-text-logout {
  color: var(--neutral-100);
  font-family: "DM Sans-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}