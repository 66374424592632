.radio-option {
  align-items: flex-start;
  display: inline-flex;
  gap: 16px;
  position: relative;
}

.radio-option .item {
  height: 22px;
  margin-right: -2px;
  position: relative;
  width: 104px;
}

.radio-option .upload-media {
  color:  #717579;
  font-family: var(--h6-nunito-sans-semibold-font-family);
  font-size: var(--h6-nunito-sans-semibold-font-size);
  font-style: var(--h6-nunito-sans-semibold-font-style);
  font-weight: var(--h6-nunito-sans-semibold-font-weight);
  left: 0;
  letter-spacing: var(--h6-nunito-sans-semibold-letter-spacing);
  line-height: var(--h6-nunito-sans-semibold-line-height);
  position: absolute;
  top: 0;
  
}

.radio-option .radio-buttons {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}
