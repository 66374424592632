.apply-season-settings{
  width: 220px;
height:40px;
padding: 8px 16px;
border-radius: 8px;
border: 1px;
justify: space-between;
background: #D02B2F;
border: 1px solid #D02B2F;
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0px;
text-align: center;
color: #ffffff;
cursor: pointer;
}
.apply-season-settings:disabled{
cursor: not-allowed;
opacity: 0.5;
}

.custom-video-review-dashboard {
  background-color: #f7f7fb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.custom-video-review-dashboard .div-2 {
  background-color: #f7f7fb;
  height: 1024px;
  position: relative;
  width: 100%;
}

.custom-video-review-dashboard .overlap-2 {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.custom-video-review-dashboard .rectangle-2 {
  background-color: #ffffff;
  box-shadow: 0px 4px 24px #0000000d;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.custom-video-review-dashboard .header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.custom-video-review-dashboard .left-nav-instance {
  left: 0 !important;
  position: absolute !important;
  top: 85px !important;
}



.custom-video-review-dashboard .left-nav-3 {
  left: unset !important;
}

.custom-video-review-dashboard .frame-19 {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 324px;
  position: absolute;
  top: 105px;
  width:73%;
}

.custom-video-review-dashboard .frame-20 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.custom-video-review-dashboard .section-title {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.custom-video-review-dashboard .frame-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 636px;
}

.custom-video-review-dashboard .page-title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.custom-video-review-dashboard .pagetitle {
  color: var(--primary-text);
  font-family: 'Poppins', Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.custom-video-review-dashboard .breadcrum {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.custom-video-review-dashboard .material-symbols {
  height: 20px;
  position: relative;
  width: 20px;
}

.custom-video-review-dashboard .keyboard-arrow-right {
  height: 8px;
  position: relative;
  width: 5.33px;
}

.custom-video-review-dashboard .text-wrapper-4 {
  color: #c4c4c4;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.custom-video-review-dashboard .text-wrapper-5 {
  color: #4a3aff;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.custom-video-review-dashboard .frame-data {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px 56px;
  position: relative;
  width: 100%;
  height: 824px;
}

.custom-video-review-dashboard .frame-23 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.custom-video-review-dashboard .frame-1003-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 112px;
  position: relative;
}

.custom-video-review-dashboard .frame-1003-instance {
  flex: 0 0 auto !important;
}

.custom-video-review-dashboard .frame-24 {
  background-color: #d02b2f33 !important;
}

.custom-video-review-dashboard .frame-25 {
  background-color: #d02b2f !important;
}

.custom-video-review-dashboard .frame-26 {
  color: #d02b2f !important;
}

.custom-video-review-dashboard .line {
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 928px;
}

.custom-video-review-dashboard .frame-27 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.custom-video-review-dashboard .frame-28 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.custom-video-review-dashboard .frame-29 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  position: relative;
  width: 682.51px;
}

.custom-video-review-dashboard .text-wrapper-6 {
  color: var(--neutral-800);
  font-family: var(--headings-typography-size-3-font-family);
  font-size: var(--headings-typography-size-3-font-size);
  font-style: var(--headings-typography-size-3-font-style);
  font-weight: var(--headings-typography-size-3-font-weight);
  letter-spacing: var(--headings-typography-size-3-letter-spacing);
  line-height: var(--headings-typography-size-3-line-height);
  margin-top: -1px;
  position: relative;
  width: 202.63px;
}

.custom-video-review-dashboard .frame-30 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.custom-video-review-dashboard .frame-31 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.custom-video-review-dashboard .group-37146 {
  position: relative !important;
}

.custom-video-review-dashboard .group-instance {
  left: 13px !important;
  top: 14px !important;
}

.custom-video-review-dashboard .group-37146-instance {
  color: #c8c8c8 !important;
  font-family: "Raleway", Helvetica !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.custom-video-review-dashboard .group-2 {
  white-space: nowrap !important;
  width: fit-content !important;
}

.custom-video-review-dashboard .group-3 {
  top: 14px !important;
}

.custom-video-review-dashboard .primary-BTN-wrapper {
  all: unset;
  align-items: flex-start;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
}

.custom-video-review-dashboard .primary-BTN {
  all: unset;
  align-items: center;
  background-color: var(--primarycolor-1);
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
}

.custom-video-review-dashboard .button-text {
  all: unset;
  box-sizing: border-box;
  color: var(--neutral-100);
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}


.custom-video-review-dashboard .frame-datasearch {
  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  height: 56px;
  left: 0;
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
}

.custom-video-review-dashboard .frame-datasearch2 {
  height: 40px;
  left: 16px;
  position: relative;
  top: 8px;
  width: 50%;
}

.custom-video-review-dashboard .frame-datafilter {
  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  height: 56px;
  left: 0;
  position: relative;
  top: 0;
  width: 50%;
  float:right;
}
.custom-video-review-dashboard .frame-datafilter2 {
  margin:10px;
}

.custom-video-review-dashboard .search {
  background-color: #ffffff;
  border-radius: 4px;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 320px;
border: 1px solid var(--Light-Beerus-Beerus, #EBEBEB);
}

.custom-video-review-dashboard  .label-and {
  color: #999ca0;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  width: 272px;
}


.custom-video-review-dashboard .input {
  background: transparent;
  border: none;
  color: #999ca0;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0;
  padding-left: 8px;
  height: 40px;
  width: 272px;
}

.custom-video-review-dashboard .generic {
  height: 24px;
  left: 288px;
  position: absolute;
  top: 8px;
  width: 24px;
}

.custom-video-review-dashboard .filter-icon {
  height: 32px;
  left: 336px;
  position: absolute;
  top: 4px;
  width: 32px;
}

.custom-video-review-dashboard .frame-datagrid {
  height: 768px;
  left: 0;
  position: absolute;
  top: 56px;
  width: 100%;
}