 .popup-startup-auth {
  align-items: center;
  border-radius: 8px;
  background: var(--surface, #FFF);
/* 24 dp */
  box-shadow: 0px 11px 15px 0px rgba(0, 0, 0, 0.16), 0px 9px 46px 0px rgba(0, 0, 0, 0.09), 0px 24px 38px 0px rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
  padding: 40px 0px 24px;
  position: relative;
  width: 480px;
}
 
.popup-startup-auth .title {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding: 0px 32px;
  position: relative;
  width: 100%;
}
 
.popup-startup-auth .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}
 
.popup-startup-auth .brightness-alert {
  height: 24px;
  position: relative;
  width: 24px;
}
 
.popup-startup-auth .text-wrapper {
  color: var(--on-surface);
  font-family: var(--typo-heading-06-font-family);
  font-size: var(--typo-heading-06-font-size);
  font-style: var(--typo-heading-06-font-style);
  font-weight: var(--typo-heading-06-font-weight);
  letter-spacing: var(--typo-heading-06-letter-spacing);
  line-height: var(--typo-heading-06-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
 
.popup-startup-auth .content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 0px 32px 16px;
  position: relative;
  width: 100%;
}
 
.popup-startup-auth .div {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
}
 
.popup-startup-auth .checkbox-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 2px 0px 0px;
  position: relative;
}
 
.popup-startup-auth .checkbox-instance {
  background-image: url(../../../../../static/img/fi-rr-checkbox-2.svg) !important;
  height: 14px !important;
  position: relative !important;
  width: 14px !important;
}
 
.popup-startup-auth .text {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  padding: 0px 16px 0px 0px;
  position: relative;
}
 
.popup-startup-auth .p {
  width: 100%;
  text-wrap: wrap;
  align-self: stretch;
  color: var(--on-surface-medium);
  font-family: var(--typo-para-01-font-family);
  font-size: var(--typo-para-01-font-size);
  font-style: var(--typo-para-01-font-style);
  font-weight: var(--typo-para-01-font-weight);
  letter-spacing: var(--typo-para-01-letter-spacing);
  line-height: var(--typo-para-01-line-height);
  margin-top: -1px;
  position: relative;
}
 
.popup-startup-auth .actions {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 0px 32px;
  position: relative;
  width: 100%;
}
 
.popup-startup-auth .secondary-action {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  position: relative;
}
 
.popup-startup-auth .dialog-actions {
  align-items: flex-end;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
}
 
.popup-startup-auth .button-primary {
  all: unset;
  align-items: center;
  border: 1px solid;
  border-color: #4a3aff;
  border-radius: 18px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  cursor: pointer;
}
 
.popup-startup-auth .label {
  color: #4a3aff;
  font-family: var(--typo-link-font-family);
  font-size: var(--typo-link-font-size);
  font-style: var(--typo-link-font-style);
  font-weight: var(--typo-link-font-weight);
  letter-spacing: var(--typo-link-letter-spacing);
  line-height: var(--typo-link-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
 
.popup-startup-auth .button {
  all: unset;
  align-items: center;
  background-color: #4a3aff;
  border-radius: 18px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  cursor: pointer;
}
.popup-startup-auth .button.disabled{
  opacity: 0.5;
}
 
.popup-startup-auth .label-2 {
  color: #FFFFFF;
  font-family: var(--typo-link-font-family);
  font-size: var(--typo-link-font-size);
  font-style: var(--typo-link-font-style);
  font-weight: var(--typo-link-font-weight);
  letter-spacing: var(--typo-link-letter-spacing);
  line-height: var(--typo-link-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
 