.frame-uploadimg{
  flex: 0 0 auto !important;
  padding: 0px 0px 0px 0px !important;
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  gap: 10px;
  position: relative;
}
.frame-1027 {
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  gap: 10px;
  position: relative;
}

.frame-1027 .frame-18 {
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.frame-1027 .checkbox {
  background-size: 100% 100%;
  height: 16px;
  position: relative;
  width: 16px;
  cursor: pointer;
}

.frame-1027 .i-authorise {
  color: #374957;
  font-family: "DM Sans", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-align: center;
  
}
