.btn {
    color: white;
    cursor: pointer;
    font-weight: bold;
    height: 43px;
    border-color: #828282;
    border-radius: 10px;
  }

  .btn-save,
.btn-save:hover,
.btn-save:active,
.btn-save:visited,
.btn-save:focus {
  background: #4a3aff !important;
  border-color: #4a3aff !important;
  color: white;
  width: 106px;
  cursor: pointer;
  cursor: pointer;
  font-weight: bold;
  height: 43px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 10px;

}

.btn-cancel,
.btn-cancel:hover,
.btn-cancel:active,
.btn-cancel:visited,
.btn-cancel:focus {
  background: white !important;
  border-color: #4a3aff !important;
  color: #4a3aff;
  width: 106px;
  cursor: pointer;
  cursor: pointer;
  font-weight: bold;
  height: 43px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

/* cropper.css */

.cropContainer {
  position: relative;
  width: 100%;
  height: 200px;
}

@media (min-width: 600px) {
  .cropContainer {
    height: 400px;
  }
}

.cropButton {
  flex-shrink: 0;
  margin-left: 16px;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

@media (min-width: 600px) {
  .controls {
    flex-direction: row;
    align-items: center;
  }
}

.sliderContainer {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 10px;
}

.sliderLabel {
  min-width: 65px;
}

.slider {
  padding: 22px 0px;
  margin-left: 32px;
}

@media (min-width: 600px) {
  .slider {
    flex-direction: row;
    align-items: center;
    margin: 0 16px;
  }
}