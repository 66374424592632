.frame-1026 {
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  gap: 10px;
  position: relative;
}

.frame-1026 .frame-13 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.frame-1026 .if-you-don-t-have-a {
  color: #000000;
  font-family: "DM Sans", Helvetica;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame-1026 .primary-BTN {
  all: unset;
  align-items: center;
  background-color: #ff0000;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  opacity: 0.5;
  padding: 8px 16px;
  position: relative;
  cursor: pointer;
}
.frame-1026 .primary-BTN.disabled {
  background-color: #d96868;
  opacity: 0.5;
  cursor: not-allowed;
}
.frame-1026 .button-text {
  all: unset;
  box-sizing: border-box;
  color: var(--neutral-100);
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.checkboxtext3{
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}
.frame-instance3{
  width:16px;
  height:16px;
}

