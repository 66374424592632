.timestamp-field-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .timestamp-input {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #eff0f6;
    border-radius: 8px;
    box-shadow: 0px 2px 6px #13124212;
    gap: 10px;
    padding: 10px;
    width: 100%;
    display:flex;
    position: relative;
    height: 45px;
  }
  .timestamp-label{
    color: var(--neutral-800);
    font-family: var(--other-18-20-m-font-family);
    font-size: var(--other-18-20-m-font-size);
    font-style: var(--other-18-20-m-font-style);
    font-weight: var(--other-18-20-m-font-weight);
    letter-spacing: var(--other-18-20-m-letter-spacing);
    line-height: var(--other-18-20-m-line-height);
    margin-top: -1px;
    position: relative;
    width: 69.93px;
  }
  .timestamp-format-label {
    align-items: center;
    display: inline-flex;
    gap: 5px;
    /* margin-top: 5px;  */
    color: gray; /* You can choose any color */
    font-size: 12px; /* Adjust font size as needed */
  }
  
.checkbox {
  margin-left:0.5%;
  background-size: 100% 100%;
  height: 16px;
  position: relative;
  width: 16px;
}
.i-authorise{
  color: #1c1b1f;
    font-family: var(--typo-overline-font-family);
    font-size: var(--typo-overline-font-size);
    font-style: var(--typo-overline-font-style);
    font-weight: var(--typo-overline-font-weight);
    letter-spacing: var(--typo-overline-letter-spacing);
    line-height: var(--typo-overline-line-height);
    position: relative;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
}