.notification-warning {
  height: 31px;
  width: 31px;
}

.notification-warning .overlap-group {
  background-color: var(--red);
  border: 1px solid;
  border-color: var(--background);
  border-radius: 19.5px;
  height: 8px;
  left: -2px;
  position: relative;
  top: 18px;
  width: 8px;
}

.notification-warning .text-wrapper {
  color: var(--white);
  font-family: var(--body-1-nunito-sans-bold-font-family);
  font-size: var(--body-1-nunito-sans-bold-font-size);
  font-style: var(--body-1-nunito-sans-bold-font-style);
  font-weight: var(--body-1-nunito-sans-bold-font-weight);
  left: 14px;
  letter-spacing: var(--body-1-nunito-sans-bold-letter-spacing);
  line-height: var(--body-1-nunito-sans-bold-line-height);
  position: absolute;
  top: 5px;
}
