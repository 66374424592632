.handshake-template .submit-button {
  background-color: blue;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.handshake-template .submit-button:hover {
  background-color: darkblue;
}

.handshake-template .submit-button.disabled {
  background-color: #d3d3d3; /* Add your preferred disabled background color */
  cursor: not-allowed;
  /* Add any other styles for the disabled state */
}

.handshake-template .timestamp-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.handshake-template .clientId-input {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #eff0f6;
  border-radius: 8px;
  box-shadow: 0px 2px 6px #13124212;
  gap: 10px;
  padding: 10px;
  width: 100%;
  display:flex;
  position: relative;
  height: 45px;
}
.handshake-template .clientId-label{
  color: var(--neutral-800);
  font-family: var(--other-18-20-m-font-family);
  font-size: var(--other-18-20-m-font-size);
  font-style: var(--other-18-20-m-font-style);
  font-weight: var(--other-18-20-m-font-weight);
  letter-spacing: var(--other-18-20-m-letter-spacing);
  line-height: var(--other-18-20-m-line-height);
  margin-top: -1px;
  position: relative;
  width: 69.93px;
}

.handshake-template .frame-22 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  position: relative;
  width: 50%;
  margin: 2% auto;
}

.handshake-template .text-wrapper {
  color: var(--primary-text);
  font-family: 'Nunito Sans-Bold', Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.handshake-template .timestamp-field-wrapperr {
  display: flex;
  align-items: center; /* Center items vertically */
}

.handshake-template .gauge-wrapper {
  text-align: center;
  /* Adjust margin as needed */
/* Adjust width as needed */
  /* height: 100px; */
}
.handshake-template .i-authorise{
  color: #1c1b1f;
    font-family: var(--typo-overline-font-family);
    font-size: var(--typo-overline-font-size);
    font-style: var(--typo-overline-font-style);
    font-weight: var(--typo-overline-font-weight);
    letter-spacing: var(--typo-overline-letter-spacing);
    line-height: var(--typo-overline-line-height);
    position: relative;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
}
.confScore {
  color: #1c1b1f;
  font-family: 'poppins';
  font-style: var(--typo-overline-font-style);
  font-weight: var(--typo-overline-font-weight);
  letter-spacing: var(--typo-overline-letter-spacing);
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}
.confScore-inner {
  font-weight: 600;
  font-size: large;
}
.radial-gauge{
  margin: 0 auto;
}
