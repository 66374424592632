.btn {
    color: white;
    cursor: pointer;
    font-weight: bold;
    height: 43px;
    border-color: #828282;
    border-radius: 10px;
  }

  .btn-save,
.btn-save:hover,
.btn-save:active,
.btn-save:visited,
.btn-save:focus {
  background: #4a3aff !important;
  border-color: #4a3aff !important;
  color: white;
  width: 106px;
  cursor: pointer;
  cursor: pointer;
  font-weight: bold;
  height: 43px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.btn-cancel,
.btn-cancel:hover,
.btn-cancel:active,
.btn-cancel:visited,
.btn-cancel:focus {
  background: white !important;
  border-color: #4a3aff !important;
  color: #4a3aff;
  width: 106px;
  cursor: pointer;
  cursor: pointer;
  font-weight: bold;
  height: 43px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}