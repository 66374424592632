.select-gradreel-type {
  width: 100%;
}

.select-gradreel-type .frame-select-gradreel {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 408px;
}

.select-gradreel-type .frame-select-gradreel2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.select-gradreel-type .frame-select-gradreelheader {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  position: relative;
}

.select-gradreel-type .text-wrapper-8 {
  color: var(--neutral-800);
  font-family: var(--headings-typography-size-3-font-family);
  font-size: var(--headings-typography-size-3-font-size);
  font-style: var(--headings-typography-size-3-font-style);
  font-weight: var(--headings-typography-size-3-font-weight);
  letter-spacing: var(--headings-typography-size-3-letter-spacing);
  line-height: var(--headings-typography-size-3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.select-gradreel-type .frame-select-gradreelbuttons {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.select-gradreel-type .frame-select-gradreelbuttons2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.select-gradreel-type .standard-custom-gradreel {
  margin-right: -71px !important;
}

.select-gradreel-type .frame-27 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
}

.select-gradreel-type .primary-BTN {
  all: unset;
  align-items: center;
  border: 2px solid;
  border-color: #a0a3bd;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
}

.select-gradreel-type .button-text {
  all: unset;
  box-sizing: border-box;
  color: #a0a3bd;
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.select-gradreel-type .button-text-wrapper {
  align-items: center;
  background-color: var(--primarycolor-1);
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
}

.select-gradreel-type .button-text-2 {
  color: var(--neutral-100);
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.select-gradreel-type .left-nav-instance {
  left: 0 !important;
  position: absolute !important;
  top: 85px !important;
}

.select-gradreel-type .left-nav-2 {
  background-image: url(../../../static/img/rectangle-60-1.svg) !important;
  height: 951px !important;
  left: unset !important;
  width: 296px !important;
}

.select-gradreel-type .left-nav-3 {
  left: unset !important;
}
