/* .round-button-accept {
    border-radius: 50%;
    width: 25px; 
    height: 25px; 
    background-color: green; 
    border-color: white;
  }
  .round-button-reject{
    border-radius: 50%;
    width: 25px; 
    height: 25px; 
    background-color: red; 
    border-color: white;
  }
  
  .accept {
    background-color: green;
    color: white;
  }
  
  .reject {
    background-color: red;
    color: white;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .button-text {
    color: green; 
    font-size: 2px; 
    margin-top: 5px;
  }

   */

   .round-button-accept {
    border-radius: 50%;
    width: 25px; 
    height: 25px; 
    background-color: green; 
    border-color: white;
    
  }
  
  .round-button-reject {
    border-radius: 50%;
    width: 25px; 
    height: 25px; 
    background-color: red; 
    border-color: white;
    
  }
  
  .accept {
    background-color: green;
    color: white;
  }
  
  .reject {
    background-color: red;
    color: white;
  }
  
  .button-container {
    display: flex;
    align-items: center;
  }
  
  .button-container .round-button-reject {
    margin-left: 10px; /* Adjust the margin to create space between the buttons */
  }
  
  .button-text {
    color: green; /* Use the same color as the button or adjust as needed */
    font-size: 12px; /* Adjust the font size as needed */
    margin-left: 5px; /* Adjust the margin to create space between the button and text */
  }
  
 .table-cells {
    align-items: center;
    display: inline-flex;
    gap: 8px;
    height: 64px;
    padding: 0px 8px;
    position: relative;
  }
  
 .thumb-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
  }
  
  .thumb {
    height: 35px;
    position: relative;
    width: 80px;
    display: flex; /* Make the thumb a flex container */
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    overflow: hidden; /* Ensure any overflow is hidden */
}


  .thumb img {
    max-width: 100%; /* Ensure the image does not exceed the container's width */
    max-height: 100%; /* Ensure the image does not exceed the container's height */
    object-fit: contain; /* Ensure the image maintains its aspect ratio while fitting within the container */
    object-position: center; /* Center the image within the container */
}

  
  
 .overlap-group-wrapper {
    height: 48px;
    width: 86px;
  }
  
 .overlap-group {
    height: 48px;
    position: relative;
    cursor: pointer;
  }