.standard-custom {
  position: relative;
  width: 479px;
}

.standard-custom .frame-1023 {
  justify-content: flex-end !important;
  left: 0 !important;
  padding: 0px 0px 0px 6px !important;
  position: absolute !important;
  top: 0 !important;
}

.standard-custom .class-14 {
  background-image: url(../../../static/img/fi-rr-checkbox-1.svg) !important;
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.standard-custom .class-15 {
  background-image: url(../../../static/img/fi-rr-checkbox.svg) !important;
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.standard-custom .frame-instance {
  padding: 8px 0px !important;
  cursor: pointer;
}

.standard-custom .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  left: 0;
  position: absolute;
  top: 40px;
}

.standard-custom .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.standard-custom .frame-11 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 8px;
  position: relative;
}

.standard-custom .a-standard-gradreel {
  color: #434343;
  margin-top: -1px;
  position: relative;
  width: 450px;
}

.standard-custom .span {
  color: #434343;
  font-family: "Raleway", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
}

.standard-custom .text-wrapper-4 {
  font-weight: 700;
}

.standard-custom .table-cells {
  align-items: center;
  display: inline-flex;
  gap: 8px;
  height: 64px;
  padding: 0px 8px;
  position: relative;
}

.standard-custom .thumb-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.standard-custom .thumb {
  height: 48px;
  position: relative;
  width: 86.4px;
}

.standard-custom .overlap-group-wrapper {
  height: 48px;
  width: 86px;
}

.standard-custom .overlap-group {
  height: 48px;
  position: relative;
  cursor: pointer;
}

.standard-custom .microsoftteams-image-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 48px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 86px;
}

.standard-custom .microsoftteams-image {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.standard-custom .play-button-wrapper {
  height: 18px;
  left: 34px;
  position: absolute;
  top: 15px;
  width: 18px;
}

.standard-custom .play-button {
  height: 13px;
  left: 4px;
  position: absolute;
  top: 3px;
  width: 11px;
}

.standard-custom .a-custom-gradreel {
  color: #434343;
  margin-top: -1px;
  position: relative;
  width: 463px;
}

.standard-custom .don-t-worry-if-you {
  color: #434343;
  font-family: var(--typo-para-01-font-family);
  font-size: var(--typo-para-01-font-size);
  font-style: var(--typo-para-01-font-style);
  font-weight: var(--typo-para-01-font-weight);
  letter-spacing: var(--typo-para-01-letter-spacing);
  line-height: var(--typo-para-01-line-height);
  margin-top: -1px;
  position: relative;
  width: 463px;
}

.standard-custom.property-1-default {
  height: 40px;
  overflow: hidden;
  margin:5px 0 0 5px;
}

.standard-custom.property-1-variant-2 {
  height: 344px;
  margin:5px 0 0 5px;
}

.standard-custom.property-1-default .a-standard-gradreel {
  font-family: "Raleway", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
}

.standard-custom.property-1-variant-2 .a-standard-gradreel {
  font-family:var(--typo-para-01-font-family);
  font-size: var(--typo-para-01-font-size);
  font-style: var(--typo-para-01-font-style);
  font-weight: var(--typo-para-01-font-weight);
  letter-spacing: var(--typo-para-01-letter-spacing);
  line-height: var(--typo-para-01-line-height);
}

.standard-custom.property-1-default .a-custom-gradreel {
  font-family: "Raleway", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
}

.standard-custom.property-1-variant-2 .a-custom-gradreel {
  font-family: var(--typo-para-01-font-family);
  font-size: var(--typo-para-01-font-size);
  font-style: var(--typo-para-01-font-style);
  font-weight: var(--typo-para-01-font-weight);
  letter-spacing: var(--typo-para-01-letter-spacing);
  line-height: var(--typo-para-01-line-height);
}
/* Add these styles to your existing style.css or define them as needed */

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.video-player-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:100px
}

.close-button {
  position: absolute;
  top: 80px;
  right: 200px;
  cursor: pointer;
  font-size: 32px;
  color: #666; /* Adjust the color as needed */
  background: none;
  border: none;
  padding: 0;
}

.close-button:hover {
  color: #999; /* Adjust the hover color as needed */
}

.video-player {
  width: 60%;
}
