.audio-clip-section {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.audio-clip-section .frame-23 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.audio-clip-section .text-wrapper-6 {
  color: var(--neutral-800);
    font-family: var(--headings-typography-size-3-font-family);
    font-size: var(--headings-typography-size-3-font-size);
    font-style: var(--headings-typography-size-3-font-style);
    font-weight: var(--headings-typography-size-3-font-weight);
    letter-spacing: var(--headings-typography-size-3-letter-spacing);
    line-height: var(--headings-typography-size-3-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.audio-clip-section .frame-24 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  position: relative;
}

.audio-clip-section .frame-25 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.audio-clip-section .radio-btn-music-clip-instance {
  flex: 0 0 auto !important;
}

.audio-clip-section .radio-btn-music-clip-2 {
  width: 140px !important;
}

.audio-clip-section .radio-btn-music-clip-3 {
  width: 140px !important;
}

/* .audio-clip-section .frame-26 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
} */

.audio-clip-section .group-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.audio-clip-section .dropdown-instance {
  position: relative !important;
}

.audio-clip-section .group-2 {
  white-space: nowrap !important;
  width: fit-content !important;
}

.audio-clip-section .frame-instance {
  flex: 0 0 auto !important;
  padding: 0px 0px 0px 6px !important;
}

.audio-clip-section .radio-buttons-2 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.audio-clip-section .frame-27 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.audio-clip-section .audio-clip {
  height: 64px !important;
  position: relative !important;
  width: 221.44px !important;
}

.audio-clip-section.property-1-3-default {
  gap: 32px;
}

.audio-clip-section.property-1-3-variant-2 {
  gap: 16px;
}

.audio-clip-section.property-1-3-default .frame-23 {
  gap: 16px;
}

.audio-clip-section.property-1-3-variant-2 .frame-23 {
  gap: 7px;
}

.audio-clip-container {
  display: flex;
  justify-content: center; /* Optional: Align the clips horizontally in the center */
}

.frame-26 {
  display: flex;
  align-items: center;
  margin-right: 30px; /* Adjust margin as needed */
}
