.index-reason {
    /* background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%; */
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #d4d4d4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    z-index-reason: 1000; 
    justify-content: center;
    border-radius: 5px;
    margin-left: -190px;
    height:208px;
    width:250px;
  }
  
  .index-reason-reason .frame-wrapper {
    border: 1px none;
    height: 129px;
    width: 185px;
  }
  
  .index-reason-reason .frame {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 4px 24px #7b7b7b0f;
    height: 129px;
    position: relative;
  }
  
  .index-reason .div-wrapper {
    height: 77px;
    left:-85px;
    position: absolute;
    top: 8px;
    width: 169px;
  }
  
  .index-reason .div {
    height: 77px;
  }
  
  .index-reason .frame-2 {
    height: 77px;
    width: 169px;
  }
  
  .index-reason .frame-3 {
    height: 77px;
    position: relative;
  }
  
  .index-reason .reason-for-rejection {
    /* color: #374957;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: -1px;
    white-space: nowrap; */
    color: #374957;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 96px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    /* top: -140px; */
    white-space: nowrap;
  }
  
  .index-reason .text-wrapper {
    color: #374957;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
  }
  
  .index-reason .rectangle {
    /* background-color: #f8f8f8;
    border-radius: 8px;
    height: 100px;
    left: 0;
    position: absolute;
    top: 28px;
    width: 200px; */
    background-color: #f8f8f8;
    border-radius: 8px;
    height: 100px;
    left: 92px;
    position: absolute;
    top: 27px;
    width: 200px;
  }
  
  .index-reason .frame-4 {
    height: 28px;
    left: 368px;

    position: absolute;
    top: 93px;
    width: 137px;
  }
  
  .index-reason .primary-BTN {
    all: unset;
    border-radius: 4px;
    box-shadow: 0px 3px 12px #4939ff2e;
    box-sizing: border-box;
    height: 28px;
    left: -300px;
    position: absolute;
    top: 50px;
    width: 71px;
  }
  
  .index-reason .button-text {
    color: #89939e;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 8px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 3px;
    white-space: nowrap;
  }
  
  .index-reason .span {
    color: #89939e;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
  }
  
  .index-reason .button-text-wrapper {
    all: unset;
    background-color: #4a3aff;
    border-radius: 4px;
    box-shadow: 0px 3px 12px #4939ff2e;
    box-sizing: border-box;
    height: 28px;
    left: -220px;
    position: absolute;
    top: 50px;
    width: 56px;
  }
  
  .index-reason .span-wrapper {
    color: #ffffff;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    left: 8px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    text-align: center;
    top: 3px;
    white-space: nowrap;
  }
  
  .index-reason .text-wrapper-2 {
    color: #ffffff;
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
  }

  .textarea-css{
    width:200px;
    height: 100px;
  }
  