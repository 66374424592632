.post-event-overview {
  background-color: #f7f7fb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.post-event-overview .div-2 {
  background-color: #f7f7fb;
  height: 1280px;
  position: relative;
  width: 100%;
}

.post-event-overview .overlap-2 {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.post-event-overview .rectangle-2 {
  background-color: #ffffff;
  box-shadow: 0px 4px 24px #0000000d;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.post-event-overview .header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.post-event-overview .left-nav-instance {
  left: 0 !important;
  position: absolute !important;
  top: 85px !important;
  height: 1200px;
}



.post-event-overview .left-nav-3 {
  left: unset !important;
}

.post-event-overview .frame-19 {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 324px;
  position: absolute;
  top: 109px;
  width:73%;
}

.post-event-overview .frame-20 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.post-event-overview .section-title {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.post-event-overview .frame-34.disabled {
  pointer-events: none; /* This disables mouse events on the div */
  opacity: 0.5; /* You can also adjust opacity to visually indicate it's disabled */
}

.post-event-overview .frame-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 636px;
}

.post-event-overview .page-title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.post-event-overview .text-wrapper-3 {
  color: var(--primary-text);
  font-family: 'Nunito Sans-Bold', Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.post-event-overview .breadcrum {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.post-event-overview .material-symbols {
  height: 20px;
  position: relative;
  width: 20px;
}

.post-event-overview .keyboard-arrow-right {
  height: 8px;
  position: relative;
  width: 5.33px;
}

.post-event-overview .text-wrapper-4 {
  color: #c4c4c4;
  font-family: 'Poppins', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.post-event-overview .text-wrapper-5 {
  color: #4a3aff;
  font-family: 'Poppins-Regular', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.post-event-overview .frame-22 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  position: relative;
  width: 100%;
}

.post-event-overview .frame-23 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.post-event-overview .frame-1003-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 112px;
  position: relative;
}

.post-event-overview .frame-1003-instance {
  flex: 0 0 auto !important;
}

.post-event-overview .frame-24 {
  background-color: #d02b2f33 !important;
}

.post-event-overview .frame-25 {
  background-color: #d02b2f !important;
}

.post-event-overview .frame-26 {
  color: #d02b2f !important;
}

.post-event-overview .line {
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 928px;
}

.post-event-overview .frame-27 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.post-event-overview .frame-28 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.post-event-overview .frame-29 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  position: relative;
  width: 682.51px;
}

.post-event-overview .text-wrapper-6 {
  color: var(--neutral-800);
  font-family: var(--headings-typography-size-3-font-family);
  font-size: var(--headings-typography-size-3-font-size);
  font-style: var(--headings-typography-size-3-font-style);
  font-weight: var(--headings-typography-size-3-font-weight);
  letter-spacing: var(--headings-typography-size-3-letter-spacing);
  line-height: var(--headings-typography-size-3-line-height);
  margin-top: -1px;
  position: relative;
  width: 202.63px;
}

.post-event-overview .frame-30 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.post-event-overview .frame-31 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.post-event-overview .group-37146 {
  position: relative !important;
}

.post-event-overview .group-instance {
  left: 13px !important;
  top: 14px !important;
}

.post-event-overview .group-37146-instance {
  color: #c8c8c8 !important;
  font-family: "Raleway", Helvetica !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.post-event-overview .group-2 {
  white-space: nowrap !important;
  width: fit-content !important;
}

.post-event-overview .group-3 {
  top: 14px !important;
}

.post-event-overview .primary-BTN-wrapper {
  all: unset;
  align-items: flex-start;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
}

.post-event-overview .primary-BTN {
  all: unset;
  align-items: center;
  background-color: var(--primarycolor-1);
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.post-event-overview .primary-BTN-2{
  all: unset;
    align-items: center;
    border: 2px solid;
    border-color: #a0a3bd;
    border-radius: 56px;
    box-shadow: 0px 3px 12px #4939ff2e;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 20px 40px 21px;
    position: relative;
    cursor: pointer;
}

.post-event-overview .button-text {
  all: unset;
  box-sizing: border-box;
  color: var(--neutral-100);
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.post-event-overview .button-text-2 {
  all: unset;
  box-sizing: border-box;
  color: #a0a3bd;
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.property-default-wrapper-music .event-ID {
  width: 100%;
}
.frame-streaming-provider{
  margin-top:20px;
}

.progress-percent{
  color: red;
  font-weight: bold;
}