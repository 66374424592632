.frame {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 0px;
  position: relative;
}

.frame .image {
  height: 47px;
  position: relative;
  width: 204px;
}
