.media-details {
  background-color: #f7f7fb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 1280px;
}

.media-details .overlap {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.media-details .rectangle {
  background-color: #ffffff;
  box-shadow: 0px 4px 24px #0000000d;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.media-details .header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.media-details .left-nav-instance {
  left: 0 !important;
  position: absolute !important;
  top: 85px !important;
  height: 1200px;
}



.media-details .left-nav-3 {
  left: unset !important;
}

.media-details .frame-29 {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 324px;
  position: absolute;
  top: 109px;
  width:73%;
}

.media-details .frame-30 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.media-details .section-title {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.media-details .frame-31 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 636px;
}

.media-details .page-title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.media-details .text-wrapper-7 {
  color: var(--primary-text);
  font-family: 'Nunito Sans-Bold', Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.media-details .div-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.media-details .material-symbols {
  height: 20px;
  position: relative;
  width: 20px;
}

.media-details .keyboard-arrow-right {
  height: 8px;
  position: relative;
  width: 5.33px;
}

.media-details .text-wrapper-8 {
  color: #c4c4c4;
    font-family: "Poppins", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.media-details .text-wrapper-9 {
  color: #4a3aff;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.media-details .frame-32 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px 56px;
  position: relative;
  width: 100%;
  min-height: 100%;
}

.media-details .design-component-instance-node-2 {
  flex: 0 0 auto !important;
}

.media-details .frame-33 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  min-height: 100%;
  width: 100%;
}

.media-details .frame-34 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  gap:20px
}

.media-details .frame-35 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  position: relative;
  width: 682.51px;
}

.media-details .text-wrapper-10 {
  color: var(--neutral-800);
  font-family: var(--headings-typography-size-3-font-family);
  font-size: var(--headings-typography-size-3-font-size);
  font-style: var(--headings-typography-size-3-font-style);
  font-weight: var(--headings-typography-size-3-font-weight);
  letter-spacing: var(--headings-typography-size-3-letter-spacing);
  line-height: var(--headings-typography-size-3-line-height);
  margin-top: 35px;
  position: relative;
  /* width: 202.63px; */
}

.media-details .frame-36 {
  align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 24px;
    justify-content: flex-end;
    position: relative;
}

.media-details .group-3 {
  position: relative !important;
}

.media-details .group-4 {
  white-space: nowrap !important;
  width: fit-content !important;
  font-family: "DMSans-Regular", Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.media-details .isolation-mode {
  height: 24px;
  left: 437px;
  position: absolute;
  top: 40px;
  width: 20px;
  cursor: pointer;
}

.media-details .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.media-details .audio-clip-section-instance {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.media-details .frame-37 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.media-details .frame-38 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.media-details .frame-1026 {
  background-image: url(../../../../static/img/fi-rr-checkbox-9.svg) !important;
  position: relative !important;
}

.media-details .frame-39 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.media-details .frame-40 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.media-details .frame-41 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
  width: 864px;
}

.media-details .frame-42 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.media-details .frame-43 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  opacity: 0.5;
  position: relative;
}

.media-details .frame-44 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.media-details .group-5 {
  color: #c8c8c8 !important;
}

.media-details .group-6 {
  height: 10px !important;
  left: 1px !important;
  top: 3px !important;
  width: 14px !important;
}

.media-details .design-component-instance-node-3 {
  background-image: url(../../../../static/img/fi-rr-checkbox-13.png) !important;
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.media-details .group-7 {
  color: #6e6b8f80 !important;
}

.media-details .group-8 {
  height: 108px;
  position: relative;
  width: 420px;
}

.media-details .frame-45 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  top: 4px;
}

.media-details .frame-46 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.media-details .p {
  color: transparent;
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.media-details .span {
  color: #170f49;
  font-family: var(--other-18-20-m-font-family);
  font-size: var(--other-18-20-m-font-size);
  font-style: var(--other-18-20-m-font-style);
  font-weight: var(--other-18-20-m-font-weight);
  letter-spacing: var(--other-18-20-m-letter-spacing);
  line-height: var(--other-18-20-m-line-height);
}

.media-details .text-wrapper-11 {
  color: #9c9c9c;
  font-size: 12px;
}

.media-details .frame-47 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.media-details .frame-48 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #eff0f6;
  border-radius: 8px;
  box-shadow: 0px 2px 6px #13124212;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 16px;
  position: relative;
}

.media-details .element-wrapper {
  align-items: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: 362px;
}

.media-details .element {
  color: var(--neutral-600);
  font-family: var(--other-18-20-r-font-family);
  font-size: var(--other-18-20-r-font-size);
  font-style: var(--other-18-20-r-font-style);
  font-weight: var(--other-18-20-r-font-weight);
  letter-spacing: var(--other-18-20-r-letter-spacing);
  line-height: var(--other-18-20-r-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media-details .frame-1027-instance {
  flex: 0 0 auto !important;
  padding: 0px 0px 0px 6px !important;
}

.media-details .frame-49 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
}

.media-details .button-text-wrapper {
  all: unset;
  align-items: center;
  border: 2px solid;
  border-color: #a0a3bd;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
}

.media-details .button {
  all: unset;
  box-sizing: border-box;
  color: #a0a3bd;
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}


.media-details .primary-BTN-2 {
  all: unset;
  align-items: center;
  border: 2px solid;
  border-color: #a0a3bd;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.media-details .button-text-2 {
  all: unset;
  box-sizing: border-box;
  color: #a0a3bd;
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.media-details .primary-BTN-3 {
  all: unset;
  align-items: center;
  background-color: var(--primarycolor-1);
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}
.media-details .primary-BTN-3.disabled {
  background-color: #d3d3d3; /* Add your preferred disabled background color */
  cursor: not-allowed;
  /* Add any other styles for the disabled state */
}
.media-details .button-text-3 {
  all: unset;
  box-sizing: border-box;
  color: var(--neutral-100);
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

#streamingurl{
  align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #eff0f6;
    border-radius: 8px;
    box-shadow: 0px 2px 6px #13124212;
    gap: 10px;
    padding: 15px;
    width:420px;
    color: black;
}

#streamingprovider{
  align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #eff0f6;
    border-radius: 8px;
    box-shadow: 0px 2px 6px #13124212;
    gap: 10px;
    padding: 15px;
    width:420px;
}

.event-ID-wrapper {
  display: inline-flex;
  flex-direction: column;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.event-ID {
  color: var(--neutral-800);
  font-family: var(--other-18-20-m-font-family);
  font-size: 18px;
  font-style: var(--other-18-20-m-font-style);
  font-weight: 500;
  letter-spacing: var(--other-18-20-m-letter-spacing);
  line-height: 20px;
  position: relative;
  width: 100%;
}

/* Add the following styles to your CSS file or style section */

/* Style for the checkbox wrapper */
.checkbox-wrapper {
  display: flex;
  align-items: center; /* Align items vertically center */
}

/* Style for the checkbox */
.checkbox-label{
  color: #1c1b1f;
  font-family: var(--typo-overline-font-family);
  font-size: var(--typo-overline-font-size);
  font-style: var(--typo-overline-font-style);
  font-weight: var(--typo-overline-font-weight);
  letter-spacing: var(--typo-overline-letter-spacing);
  line-height: var(--typo-overline-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.checkbox-label2{
  color: #000;
  font-family: var(--typo-overline-font-family);
  font-size: 12px;
  font-style: var(--typo-overline-font-style);
  font-weight: var(--typo-overline-font-weight);
  letter-spacing: var(--typo-overline-letter-spacing);
  line-height: var(--typo-overline-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.checkboxtext{
  align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
    margin-left:5px;
}
.checkboxtext2{
  align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
}
.frame-instance{
  width:16px;
  height:16px;
}
.frame-instance2{
  width:24px;
  height:24px;
}
.button-text-wrapper.delete-button{
  all: unset;
  align-items: center;
  background-color: #ff0000;;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  opacity: 0.5;
  padding: 8px 16px;
  position: relative;
}
.delete-button .button{
  all: unset;
  box-sizing: border-box;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #ff0000;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Optional: add a semi-transparent overlay */
}