.left-nav {
  height: 939px;
  width: 280px;
  background-color: #ffffff;
  box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.1);
}

.left-nav .overlap-group-2 {
 
  background-size: 100% 100%;
  height: 100%;
  left: unset !important;
  position: relative;
  top: -12px;
  width: 304px;
}

.left-nav .frame-11 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  left: 8px;
  padding: 24px 0px 0px;
  position: relative;
  top: 12px;
  width: 280px;
}

.left-nav .home-instance {
  position: relative !important;
}

.left-nav .home-3 {
  background-color: unset !important;
  position: relative !important;
}

.left-nav .home-4 {
  color: #4a3aff !important;
}
