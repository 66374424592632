.excel-upload-component {
    width: 420px;
  }
  
  .excel-upload-component .frame-15 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
  }
  
  .excel-upload-component .event-ID-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    position: relative;
  }
  
  .excel-upload-component .event-ID {
    color: var(--neutral-800);
    font-family: var(--other-18-20-m-font-family);
    font-size: var(--other-18-20-m-font-size);
    font-style: var(--other-18-20-m-font-style);
    font-weight: var(--other-18-20-m-font-weight);
    letter-spacing: var(--other-18-20-m-letter-spacing);
    line-height: var(--other-18-20-m-line-height);
    margin-top: -1px;
    position: relative;
    /* width: 69.93px; */
  }
  
  .excel-upload-component .frame-16 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative;
  }
  
  .excel-upload-component .frame-17 {
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .excel-upload-component .frame-18 {
    align-items: center;
    display: flex;
    gap: 10px;
    position: relative;
    width: 362px;
  }
  
  .excel-upload-component .text-wrapper-4 {
    color: var(--neutral-600);
    font-family: "Raleway", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .excel-upload-component .frame-19 {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #eff0f6;
    border-radius: 8px 8px 0px 0px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 16px;
    position: relative;
  }
  
  .excel-upload-component .frame-20 {
    align-items: flex-start;
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #f2f2f2;
    border-left-style: solid;
    border-left-width: 1px;
    border-radius: 0px 0px 8px 8px;
    border-right-style: solid;
    border-right-width: 1px;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  }
  
  .excel-upload-component .frame-21 {
    align-items: center;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #eff0f6;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 16px;
    position: relative;
  }
  
  .excel-upload-component .instance-node {
    height: 16px !important;
    position: relative !important;
    width: 16px !important;
  }
  
  .excel-upload-component .frame-22 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    padding: 16px;
    position: relative;
  }
  
  .excel-upload-component .text-wrapper-5 {
    color: var(--neutral-600);
    font-family: "Raleway", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .excel-upload-component .frame-1027 {
    flex: 0 0 auto !important;
    padding: 0px 0px 0px 6px !important;
  }
  
  .excel-upload-component.property-1-0-default {
    height: 120px;
  }
  
  .excel-upload-component.property-1-0-variant-3 {
    height: 108px;
  }
  
  .excel-upload-component.property-1-0-variant-2 {
    height: 332px;
  }
  
  .excel-upload-component.property-1-0-default .frame-15 {
    top: 4px;
  }
  
  .excel-upload-component.property-1-0-variant-3 .frame-15 {
    top: 4px;
  }
  
  .excel-upload-component.property-1-0-variant-2 .frame-15 {
    top: 12px;
  }
  
  .excel-upload-component.property-1-0-default .frame-17 {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #eff0f6;
    border-radius: 8px;
    box-shadow: 0px 2px 6px #13124212;
    gap: 10px;
    padding: 15px;
    cursor: pointer;
  }
  
  .excel-upload-component.property-1-0-variant-3 .frame-17 {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #eff0f6;
    border-radius: 8px;
    box-shadow: 0px 2px 6px #13124212;
    gap: 10px;
    padding: 16px;
  }
  
  .excel-upload-component.property-1-0-variant-2 .frame-17 {
    align-items: flex-start;
    flex-direction: column;
  }
  
  