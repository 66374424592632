.subitem {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 40px;
  padding: 0px 10px 0px 35px;
  position: relative;
  width: 264px;
}

.subitem .home-2 {
  color: #1e1e1e;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.subitem.variant-3 {
  background-color: #1862ff33;
  border-radius: 8px;
}

.subitem.variant-2 {
  background-color: #1862ff33;
  border-radius: 8px;
}

.subitem.frame-966 .home-2 {
  font-weight: 400;
}

.subitem.variant-3 .home-2 {
  font-weight: 500;
}

.subitem.variant-2 .home-2 {
  font-weight: 500;
}
