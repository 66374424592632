.Confirm-Pre-Event-Details {
    background-color: #f7f7fb;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

    .Confirm-Pre-Event-Details .div-2 {
        background-color: #f7f7fb;
        height: 1024px;
        position: relative;
        width: 100%;
    }

    .Confirm-Pre-Event-Details .overlap-2 {
        height: 83px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .Confirm-Pre-Event-Details .rectangle-2 {
        background-color: #ffffff;
        box-shadow: 0px 4px 24px #0000000d;
        height: 64px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .Confirm-Pre-Event-Details .header-instance {
        left: 0 !important;
        position: absolute !important;
        top: 0 !important;
    }

    .Confirm-Pre-Event-Details .frame-19 {
        align-items: flex-end;
        display: inline-flex;
        flex-direction: column;
        gap: 24px;
        left: 324px;
        width: 73%;
        position: absolute;
        top: 109px;
    }

    .Confirm-Pre-Event-Details .frame-20 {
        align-items: flex-start;
        align-self: stretch;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 24px;
        position: relative;
        width: 100%;
    }

    .Confirm-Pre-Event-Details .section-title {
        align-items: center;
        display: flex;
        flex: 0 0 auto;
        gap: 20px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .frame-21 {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 12px;
        position: relative;
        width: 636px;
    }

    .Confirm-Pre-Event-Details .page-title {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 10px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .text-wrapper-3 {
        color: var(--primary-text);
        font-family: 'Nunito Sans-Bold', Helvetica;
        font-size: 21px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: normal;
        margin-top: -1px;
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }

    .Confirm-Pre-Event-Details .div-3 {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .material-symbols {
        height: 20px;
        position: relative;
        width: 20px;
    }

    .Confirm-Pre-Event-Details .keyboard-arrow-right {
        height: 8px;
        position: relative;
        width: 5.33px;
    }

    .Confirm-Pre-Event-Details .text-wrapper-4 {
        color: #c4c4c4;
        font-family: "Poppins", Helvetica;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }

    .Confirm-Pre-Event-Details .text-wrapper-5 {
        color: #4a3aff;
        font-family: "Poppins-Regular", Helvetica;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: normal;
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }

    .Confirm-Pre-Event-Details .frame-22 {
        align-items: center;
        background-color: #ffffff;
        border-radius: 24px;
        box-shadow: 0px 4px 16px #080f340f;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 32px;
        padding: 32px 56px;
        width: 100%;
        position: relative;
    }

    .Confirm-Pre-Event-Details .frame-23 {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 32px;
        position: relative;
        width: 100%;
    }

    .Confirm-Pre-Event-Details .frame-1003-wrapper {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 112px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .frame-24 {
        flex: 0 0 auto !important;
    }

    .Confirm-Pre-Event-Details .frame-1003-instance {
        color: #d02b2f !important;
    }

    .Confirm-Pre-Event-Details .frame-25 {
        background-color: #d02b2f33 !important;
    }

    .Confirm-Pre-Event-Details .line {
        height: 1px;
        object-fit: cover;
        position: relative;
        width: 928px;
    }

    .Confirm-Pre-Event-Details .frame-26 {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 32px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .frame-27 {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .frame-28 {
        align-items: flex-start;
        border-radius: 8px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 10px;
        padding: 8px 0px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .text-wrapper-6 {
        color: #374957;
        font-family: "SF Pro Rounded-Semibold", Helvetica;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 20px;
        margin-top: -1px;
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }

    .Confirm-Pre-Event-Details .frame-29 {
        align-items: flex-start;
        background-color: #f8f8f8;
        border-radius: 8px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 10px;
        padding: 8px 16px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .text-wrapper-7 {
        color: #717171;
        font-family: "SF Pro Rounded-Regular", Helvetica;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 20px;
        margin-top: -1px;
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }

    .Confirm-Pre-Event-Details .frame-30 {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 117px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .frame-31 {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 16px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .frame-32 {
        align-items: flex-start;
        align-self: stretch;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 7px;
        position: relative;
        width: 100%;
    }

    .Confirm-Pre-Event-Details .text-wrapper-8 {
        color: var(--neutral-800);
        font-family: var(--headings-typography-size-3-font-family);
        font-size: var(--headings-typography-size-3-font-size);
        font-style: var(--headings-typography-size-3-font-style);
        font-weight: var(--headings-typography-size-3-font-weight);
        letter-spacing: var(--headings-typography-size-3-letter-spacing);
        line-height: var(--headings-typography-size-3-line-height);
        margin-top: -1px;
        position: relative;
        width: 202.63px;
    }

    .Confirm-Pre-Event-Details .frame-33 {
        align-items: flex-end;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 24px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .frame-34 {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 24px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .group-37146 {
        position: relative !important;
    }

    .Confirm-Pre-Event-Details .group-instance {
        white-space: nowrap !important;
        width: fit-content !important;
    }

    .Confirm-Pre-Event-Details .button-text-wrapper {
        all: unset;
        align-items: center;
        background-color: #2a16fc;
        border-radius: 56px;
        box-shadow: 0px 3px 12px #4939ff2e;
        box-sizing: border-box;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        justify-content: center;
        padding: 8px 16px;
        position: relative;
        cursor: pointer;
    }

        .Confirm-Pre-Event-Details .button-text-wrapper.disabled {
            opacity: 0.5; /* Add your preferred disabled background color */
            cursor: not-allowed;
            /* Add any other styles for the disabled state */
        }

.primary-BTN.disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
    /* Add any other styles for the disabled state */
}

.Confirm-Pre-Event-Details .button {
    all: unset;
    box-sizing: border-box;
    color: var(--neutral-100);
    font-family: "DM Sans", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.Confirm-Pre-Event-Details .frame-35 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.Confirm-Pre-Event-Details .text-wrapper-9 {
    color: #282828;
    font-family: "DM Sans", Helvetica;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.Confirm-Pre-Event-Details .group-wrapper {
    background-color: #f4f4f4;
    border: 1px dashed;
    border-color: #d9d9d9;
    border-radius: 8px;
    height: 184px;
    position: relative;
    width: 358px;
}

.Confirm-Pre-Event-Details .overlap-group-wrapper {
    height: 53px;
    left: 149px;
    position: relative;
    top: 67px;
    width: 53px;
}

.Confirm-Pre-Event-Details .overlap-group-3 {
    height: 53px;
    position: relative;
}

.Confirm-Pre-Event-Details .fi-sr-video-camera {
    height: 26px !important;
    left: 16px !important;
    position: absolute !important;
    top: 11px !important;
    width: 26px !important;
}

.Confirm-Pre-Event-Details .line-wrapper {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    left: 17px;
    padding: 10px;
    position: absolute;
    top: -1px;
    transform: rotate(45deg);
}

.Confirm-Pre-Event-Details .img {
    height: 25.51px;
    margin-left: -12.25px;
    margin-right: -13.25px;
    object-fit: cover;
    position: relative;
    transform: rotate(-45deg);
    width: 25.51px;
}

.Confirm-Pre-Event-Details .frame-1026-instance {
    background-image: url(../../../../static/img/fi-rr-checkbox.svg) !important;
    position: relative !important;
}

.Confirm-Pre-Event-Details .frame-36 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 24px;
    justify-content: flex-end;
    position: relative;
}

.Confirm-Pre-Event-Details .primary-BTN-2 {
    all: unset;
    align-items: center;
    border: 2px solid;
    border-color: #a0a3bd;
    border-radius: 56px;
    box-shadow: 0px 3px 12px #4939ff2e;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 20px 40px 21px;
    position: relative;
    cursor: pointer;
}

.Confirm-Pre-Event-Details .button-text-2 {
    all: unset;
    box-sizing: border-box;
    color: #a0a3bd;
    font-family: var(--other-18-20-b-font-family);
    font-size: var(--other-18-20-b-font-size);
    font-style: var(--other-18-20-b-font-style);
    font-weight: var(--other-18-20-b-font-weight);
    letter-spacing: var(--other-18-20-b-letter-spacing);
    line-height: var(--other-18-20-b-line-height);
    margin-top: -2px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.Confirm-Pre-Event-Details .primary-BTN-3 {
    all: unset;
    align-items: center;
    background-color: var(--primarycolor-1);
    border-radius: 56px;
    box-shadow: 0px 3px 12px #4939ff2e;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 20px 40px 21px;
    position: relative;
    cursor: pointer;
}

.Confirm-Pre-Event-Details .button-text-3 {
    all: unset;
    box-sizing: border-box;
    color: var(--neutral-100);
    font-family: var(--other-18-20-b-font-family);
    font-size: var(--other-18-20-b-font-size);
    font-style: var(--other-18-20-b-font-style);
    font-weight: var(--other-18-20-b-font-weight);
    letter-spacing: var(--other-18-20-b-letter-spacing);
    line-height: var(--other-18-20-b-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.Confirm-Pre-Event-Details .left-nav-instance {
    left: 0 !important;
    position: absolute !important;
    top: 85px !important;
}



.Confirm-Pre-Event-Details .left-nav-3 {
    left: unset !important;
}

.frame {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
}




    .frame .no-videos-no-problem {
        color: #000000;
        font-family: var(--typo-overline-font-family);
        font-size: var(--typo-overline-font-size);
        font-style: var(--typo-overline-font-style);
        font-weight: var(--typo-overline-font-weight);
        letter-spacing: var(--typo-overline-letter-spacing);
        line-height: var(--typo-overline-line-height);
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }

    .frame .frame-wrapper {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 32px;
        position: relative;
    }

    .frame .div-3 {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 80px;
        position: relative;
    }

    .frame .div-4 {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 10px;
        position: relative;
    }

    .frame .text-wrapper {
        color: #282828;
        font-family: "DM Sans-Bold", Helvetica;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 24px;
        margin-top: -1px;
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }

    .frame .div-5 {
        align-items: flex-end;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 10px;
        position: relative;
    }

    .Confirm-Pre-Event-Details .frame .group {
        height: 200px;
        position: relative;
        width: 350px;
    }

    .frame .overlap-group {
        height: 248px;
        position: relative;
    }

    .frame .microsoftteams-image {
        height: 200px;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 356px;
    }

    .frame .div-wrapper {
        height: 248px;
        left: 0;
        position: absolute;
        top: 0;
        width: 387px;
    }

    .frame .div-6 {
        align-items: flex-end;
        display: inline-flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
    }

    .frame .img {
        height: 248px;
        object-fit: cover;
        position: relative;
        width: 387px;
    }

    .frame .play-button {
        height: 59px;
        left: 173px;
        position: absolute;
        top: 94px;
        width: 53px;
    }

    .frame .div-7 {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 10px;
        position: relative;
    }

    .frame .primary-BTN {
        all: unset;
        align-items: center;
        border: 1px solid;
        border-color: #4a3aff;
        border-radius: 56px;
        box-shadow: 0px 3px 12px #4939ff2e;
        box-sizing: border-box;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        justify-content: center;
        padding: 8px 16px;
        position: relative;
        cursor: pointer;
    }

    .frame .button-text {
        all: unset;
        box-sizing: border-box;
        color: #4a3aff;
        font-family: "DM Sans-Bold", Helvetica;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 20px;
        margin-top: -1px;
        position: relative;
        text-align: center;
        white-space: nowrap;
        width: fit-content;
        cursor: pointer;
    }



    .frame .button {
        all: unset;
        box-sizing: border-box;
        color: var(--neutral-100);
        font-family: "DM Sans-Bold", Helvetica;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 20px;
        margin-top: -1px;
        position: relative;
        text-align: center;
        white-space: nowrap;
        width: fit-content;
    }

    .frame .primary-BTN-2 {
        all: unset;
        align-items: center;
        background-color: #4a3aff;
        border-radius: 56px;
        box-shadow: 0px 3px 12px #4939ff2e;
        box-sizing: border-box;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        justify-content: center;
        opacity: 0.5;
        padding: 8px 16px;
        position: relative;
        cursor: pointer;
    }



/* Style for the checkbox */
.checkbox-label-confirm {
    color: #000;
    /* Typo/Overline */
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 120% */
    letter-spacing: 0.2px;
}


.checkboxtextconfirm {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    position: relative;
}

.frame-instance-confirm {
    width: 20px;
    height: 20px;
}

.frame-model {
    align-items: flex-end;
    display: inline-flex;
    flex-direction: column;
    gap: 64px;
    position: relative;
    background: #FFF;
    border-radius: 10px;
    padding: 40px;
}

    .frame-model .div-model {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 47px;
        position: relative;
    }

    .frame-model .failed-loader {
        height: 120px !important;
        position: relative !important;
        width: 120px !important;
    }

    .frame-model .once-published {
        color: #000000;
        font-family: "DM Sans-Regular", Helvetica;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 24px;
        position: relative;
        width: 466px;
    }

    .frame-model .text-wrapper {
        line-height: 30px;
    }

    .frame-model .span {
        line-height: 50px;
    }

    .frame-model .div-2-model {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 10px;
        position: relative;
    }

    .frame-model .primary-BTN {
        align-items: center;
        border: 1px solid;
        border-color: #89939e;
        border-radius: 56px;
        box-shadow: 0px 3px 12px #4939ff2e;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        justify-content: center;
        padding: 8px 32px;
        position: relative;
    }

    .frame-model .button-text {
        color: #89939e;
        font-family: "DM Sans-Bold", Helvetica;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 20px;
        margin-top: -1px;
        position: relative;
        text-align: center;
        white-space: nowrap;
        width: fit-content;
    }

    .frame-model .button-text-wrapper {
        align-items: center;
        background-color: #4a3aff;
        border-radius: 56px;
        box-shadow: 0px 3px 12px #4939ff2e;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        justify-content: center;
        padding: 8px 32px;
        position: relative;
    }

    .frame-model .button-text-2 {
        color: var(--neutral-100);
        font-family: "DM Sans-Bold", Helvetica;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 20px;
        margin-top: -1px;
        position: relative;
        text-align: center;
        white-space: nowrap;
        width: fit-content;
        cursor: pointer;
    }

.frame-success-model {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    background: #FFF;
    padding: 50px 200px;
    border-radius: 10px;
}

    .frame-success-model .sucess-loader {
        height: 120px !important;
        position: relative !important;
        width: 120px !important;
    }

    .frame-success-model .property-1-group-37324 {
        height: 120px !important;
    }

    .frame-success-model .property-group-instance {
        height: 120px !important;
        width: 120px !important;
    }

    .frame-success-model .fi-rr-check-instance {
        height: 34px !important;
        left: 43px !important;
        position: absolute !important;
        top: 43px !important;
        transform: rotate(-90deg) !important;
        width: 34px !important;
    }

    .frame-success-model .text-wrapper-success {
        color: #1ba421;
        font-family: "DM Sans-Medium", Helvetica;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 30px;
        position: relative;
        white-space: nowrap;
        width: fit-content;
    }

.Confirm-Pre-Event-Details .primary-BTN-3.disabled {
    background-color: #d3d3d3; /* Add your preferred disabled background color */
    cursor: not-allowed;
    /* Add any other styles for the disabled state */
}


.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust as needed */
  background: #f0f0f0;
}
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5); /* Optional: add a semi-transparent overlay */
  }