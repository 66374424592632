/* .property-default {
  align-items: center;
  border: 1px dashed;
  border-color: #89939e;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
}

.property-default .congratulations-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.property-default .congratulations {
  color: #6e6e6e;
  font-family: "Enriqueta", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.property-default .fi-rr-edit-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 397px;
  padding: 8px;
  position: absolute;
  top: -14px;
}

.property-default .fi-rr-edit {
  height: 10px !important;
  position: relative !important;
  width: 16px !important;
} */

.property-default {
  align-items: center;
  border: 1px dashed;
  border-color: #89939e;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 8px 0px;
  position: relative;
}

.property-default .congratulations-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.property-default .congratulations {
  border: none;
  outline: none;
  background: none;
  color: #6e6e6e;
  font-family: "Enriqueta" ;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.property-default .fi-rr-edit-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  justify-content: end;
  padding: 8px;
  position: absolute;
  top: -22px;
  right: -22px;
}

.property-default .fi-rr-edit {
  height: 10px !important;
  position: relative !important;
  width: 16px !important;
}
