.expand {
  height: 16px;
  position: relative;
  width: 16px;
}

.expand .polygon {
  height: 7px;
  left: 3px;
  position: absolute;
  /* top: 6px; */
  top: -1px;

  width: 10px;
}
