.create-exit-clip-settings {
  background-color: #f7f7fb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.create-exit-clip-settings .div-2 {
  background-color: #f7f7fb;
  height: 1024px;
  position: relative;
  width: 100%;
}

.create-exit-clip-settings .overlap-2 {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.create-exit-clip-settings .rectangle-2 {
  background-color: #ffffff;
  box-shadow: 0px 4px 24px #0000000d;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.create-exit-clip-settings .header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.create-exit-clip-settings .frame-19 {
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 324px;
  width:73%;
  position: absolute;
  top: 109px;
}

.create-exit-clip-settings .frame-20 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.create-exit-clip-settings .section-title {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.create-exit-clip-settings .frame-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 636px;
}

.create-exit-clip-settings .page-title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.create-exit-clip-settings .text-wrapper-3 {
  color: var(--primary-text);
  font-family: 'Nunito Sans-Bold', Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-settings .div-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.create-exit-clip-settings .material-symbols {
  height: 20px;
  position: relative;
  width: 20px;
}

.create-exit-clip-settings .keyboard-arrow-right {
  height: 8px;
  position: relative;
  width: 5.33px;
}

.create-exit-clip-settings .text-wrapper-4 {
  color: #c4c4c4;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-settings .text-wrapper-5 {
  color: #4a3aff;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-settings .frame-22 {
  background-color: #ffffff;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0px 4px 16px #080f340f;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 24px 16px;
  width: 100%;
  position: relative;
}

.create-exit-clip-settings .frame-23 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%;
}

.create-exit-clip-settings .frame-1003-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 112px;
  position: relative;
}

.create-exit-clip-settings .frame-24 {
  flex: 0 0 auto !important;
}

.create-exit-clip-settings .frame-1003-instance {
  color: #d02b2f !important;
}

.create-exit-clip-settings .frame-25 {
  background-color: #d02b2f33 !important;
}

.create-exit-clip-settings .line {
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 928px;
}

.create-exit-clip-settings .frame-26 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
}

.create-exit-clip-settings .frame-27 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.create-exit-clip-settings .frame-28 {
  align-items: flex-start;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px 0px;
  position: relative;
}

.create-exit-clip-settings .text-wrapper-6 {
  color: #374957;
  font-family: "SF Pro Rounded-Semibold", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-settings .frame-29 {
  align-items: flex-start;
  background-color: #f8f8f8;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px 16px;
  position: relative;
}

.create-exit-clip-settings .text-wrapper-7 {
  color: #717171;
  font-family: "SF Pro Rounded-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-settings .frame-30 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 117px;
  position: relative;
}

.create-exit-clip-settings .frame-31 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.create-exit-clip-settings .frame-32 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  position: relative;
  width: 100%;
}

.create-exit-clip-settings .text-wrapper-8 {
  color: var(--neutral-800);
  font-family: var(--headings-typography-size-3-font-family);
  font-size: var(--headings-typography-size-3-font-size);
  font-style: var(--headings-typography-size-3-font-style);
  font-weight: var(--headings-typography-size-3-font-weight);
  letter-spacing: var(--headings-typography-size-3-letter-spacing);
  line-height: var(--headings-typography-size-3-line-height);
  margin-top: -1px;
  position: relative;
  width: 202.63px;
}

.create-exit-clip-settings .frame-33 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.create-exit-clip-settings .frame-34 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.create-exit-clip-settings .group-37146 {
  position: relative !important;
}

.create-exit-clip-settings .group-instance {
  white-space: nowrap !important;
  width: fit-content !important;
}

.create-exit-clip-settings .button-text-wrapper {
  all: unset;
  align-items: center;
  background-color: #4a3aff;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  opacity: 0.5;
  padding: 8px 16px;
  position: relative;
}

.create-exit-clip-settings .button {
  all: unset;
  box-sizing: border-box;
  color: var(--neutral-100);
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-settings .frame-35 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.create-exit-clip-settings .text-wrapper-9 {
  color: #282828;
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-settings .group-wrapper {
  background-color: #f4f4f4;
  border: 1px dashed;
  border-color: #d9d9d9;
  border-radius: 8px;
  height: 203px;
  position: relative;
  width: 358px;
}

.create-exit-clip-settings .overlap-group-wrapper {
  height: 53px;
  left: 149px;
  position: relative;
  top: 67px;
  width: 53px;
}

.create-exit-clip-settings .overlap-group-3 {
  height: 53px;
  position: relative;
}

.create-exit-clip-settings .fi-sr-video-camera {
  height: 26px !important;
  left: 16px !important;
  position: absolute !important;
  top: 11px !important;
  width: 26px !important;
}

.create-exit-clip-settings .line-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 17px;
  padding: 10px;
  position: absolute;
  top: -1px;
  transform: rotate(45deg);
}

.create-exit-clip-settings .img {
  height: 25.51px;
  margin-left: -12.25px;
  margin-right: -13.25px;
  object-fit: cover;
  position: relative;
  transform: rotate(-45deg);
  width: 25.51px;
}

.create-exit-clip-settings .frame-1026-instance {
  background-image: url(../../../../static/img/fi-rr-checkbox.svg) !important;
  position: relative !important;
}

.create-exit-clip-settings .frame-36 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
}

.create-exit-clip-settings .primary-BTN-2 {
  all: unset;
  align-items: center;
  border: 2px solid;
  border-color: #a0a3bd;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.create-exit-clip-settings .button-text-2 {
  all: unset;
  box-sizing: border-box;
  color: #a0a3bd;
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-settings .primary-BTN-3 {
  all: unset;
  align-items: center;
  background-color: var(--primarycolor-1);
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.create-exit-clip-settings .primary-BTN-3.disabled {
  background-color: #d3d3d3; /* Add your preferred disabled background color */
  cursor: not-allowed;
  /* Add any other styles for the disabled state */
}

.create-exit-clip-settings .button-text-3 {
  all: unset;
  box-sizing: border-box;
  color: var(--neutral-100);
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-clip-settings .left-nav-instance {
  left: 0 !important;
  position: absolute !important;
  top: 85px !important;
}


.create-exit-clip-settings .left-nav-3 {
  left: unset !important;
}
