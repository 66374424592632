.student-clip {
  background-color: #f7f7fb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 1280px;
}

.student-clip .div-3 {
  background-color: #f7f7fb;
  height: 1024px;
  position: relative;
  width: 100%;
}

.student-clip .overlap {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.student-clip .rectangle {
  background-color: #ffffff;
  box-shadow: 0px 4px 24px #0000000d;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.student-clip .header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.student-clip .icon-instance-node {
  height: 24px !important;
  position: absolute !important;
  width: 24px !important;
  right:0px;
}

.student-clip .icon-instance-node-upload {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
  /* right:0px; */
}

.student-clip .frame-12 {
  /* align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  height: 884px;
  left: 304px;
  overflow-y: scroll;
  position: absolute;
  top: 109px; */
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 324px;
  width: 73%;
  position: absolute;
  top: 109px;
}

.student-clip .frame-13 {
  left: 0; 
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
  top: 0;
}

.student-clip .section-title {
  /* align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 1072px;
  left: 0px; */
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative
}

.student-clip .frame-14 {
  /* align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 636px;
   */
   align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 636px;
}

.student-clip .page-title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.student-clip .text-wrapper-4 {
  /* color: var(--primary-text);
  font-family: "Nunito Sans", Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content; */
  color: var(--primary-text);
  font-family: 'Nunito Sans-Bold', Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.student-clip .breadcrum {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.student-clip .material-symbols {
  height: 20px;
  position: relative;
  width: 20px;
}

.student-clip .keyboard-arrow-right {
  height: 8px;
  position: relative;
  width: 5.33px;
}

.student-clip .text-wrapper-5 {
  color: #c4c4c4;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.student-clip .text-wrapper-6 {
  color: #4a3aff;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.student-clip .frame-15 {

  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  /* padding: 32px 56px; */
  width: 100%;
  position: relative;
}

.student-clip .design-component-instance-node-2 {
  flex: 0 0 auto !important;
}

.student-clip .frame-16 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  box-shadow: 0px 4px 16px #080f340f;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px 56px;
  width: 100%;
  position: relative;
}
.student-clip .frame-16-1 {
  align-items: flex-start;
 display: inline-flex;
 flex: 0 0 auto;
 flex-direction: column;
 gap: 32px;
 position: relative;
 width: 100%;
}

.student-clip .frame-17 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 64px;
  position: relative;
  width: 100%;
}

.student-clip .frame-18 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.student-clip .frame-19 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.student-clip .frame-20 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.student-clip .frame-21 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.student-clip .text-wrapper-7 {
  color: var(--neutral-800);
  font-family: var(--headings-typography-size-3-font-family);
  font-size: var(--headings-typography-size-3-font-size);
  font-style: var(--headings-typography-size-3-font-style);
  font-weight: var(--headings-typography-size-3-font-weight);
  letter-spacing: var(--headings-typography-size-3-letter-spacing);
  line-height: var(--headings-typography-size-3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.student-clip .frame-22 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.student-clip .frame-23 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  gap:10px;
}

.student-clip .component-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.student-clip .component-1 {
  background-image: url(../../../../static/img/vuesax-linear-teacher.svg) !important;
}

.student-clip .video-tutorial-how {
  color: transparent;
  font-family: "Raleway", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.student-clip .span {
  color: #282828;
}

.student-clip .text-wrapper-8 {
  color: #4a3aff;
  text-decoration: underline;
  cursor: pointer;
}

.student-clip .group-2 {
   background-image: none;
   background-position: 50% 50%;
  background-size: cover;
  height: 480px;
  position: relative;
  width: 880px;
  border-radius: 20px;
}

.student-clip .overlap-group-wrapper {
  height: 444px;
  left: 34px;
  position: absolute;
  top: 26px;
  width: 385px;
}


.student-clip .group-3 {
  background-image: none;
  background-size: 100% 100%;
  height: 375px;
  left: 20px;
  position: absolute;
  top: 50px;
  width: 375px;
  border-width: 10px;
  border-style: solid;
}

.student-clip .frame-241 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  opacity: 0.5;
  position: relative;
  top: 150px;
  cursor: pointer;
  gap:10px;
}

.student-clip .text-wrapper-9 {
  color: #717171;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  position: relative;
  text-align: center;
  width: 70%;
}

.student-clip .fi-rr-edit-12-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  right: -20px;
  padding: 8px;
  position: absolute;
  top: -20px;
}

.student-clip .icon-instance-node-2 {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.student-clip .frame-251 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 12px;
  height: 216px;
  left: 420px;
  position: absolute;
  top: 100px;
}

.student-clip .frame-26 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.student-clip .frame-27 {
  align-items: center;
  border: 1px dashed;
  border-color: #89939e;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 8px 0px;
  position: relative;
}

.student-clip .text-wrapper-101 {
  border: none;
  outline: none;
  background: none;
  color: #413e3e;
  font-family: "HeadLineA-Regular" ;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.student-clip .fi-rr-edit-14-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  justify-content: end;
  /* left: 140px; */
  padding: 8px;
  position: absolute;
  top: -13px;
  right: -12px;
}

.student-clip .text-wrapper-111 {
  /* color: #6e6e6e; */
  border: none;
  outline: none;
  background: none;
  font-family: "Fraunces";
  font-size: 33px;
  /* font-weight: 700; */
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.student-clip .fi-rr-edit-15-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  justify-content: end;
  /* left: 204px; */
  padding: 8px;
  position: absolute;
  top: -13px;
  right: -12px;
}

.student-clip .overlap-21 {
  height: 80px;
  position: relative;
  width: 370px;
  margin-top: 20px;
}


.student-clip .rectangle-2 {
  border: 1px dashed;
  border-color: #89939e;
  height: 64px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 370px;
}

.student-clip .frame-28 {
  align-items: center;
  display: inline-flex;
  gap: 24px;
  left: 74px;
  position: absolute;
  top: 21px;
  flex-direction: row;
  cursor: pointer;
  overflow: hidden; /* Ensure content doesn't overflow */
}

.student-clip .frame-28 img {
  max-width: 100%; /* Ensure image doesn't exceed container width */
  max-height: 100%; /* Ensure image doesn't exceed container height */
  object-fit: contain; /* Maintain aspect ratio and fit within container */
}
.student-clip .group-4 {
  height: 55px;
  position: relative;
  width: 57px;
}

.student-clip .overlap-group-3 {
  background-color: #6e6e6e;
  border-radius: 27.5px;
  height: 55px;
  position: relative;
  width: 55px;
}

.student-clip .text-wrapper-12 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 0;
  line-height: 44px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
}

.student-clip .text-wrapper-13 {
  color: #000000;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.student-clip .fi-rr-cloud-upload-wrapper {
  align-items: flex-start;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  left: 352px;
  padding: 10px;
  position: absolute;
  top: 0;
}

.student-clip .fi-rr-edit-11-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 8px;
  position: absolute;
  top: -15px;
  right:0px;
}

.student-clip .template-empty {
  height: 24px !important;
  left: 0px !important;
  position: absolute !important;
  top: -3px !important;
  width: 24px !important;
}

.student-clip .frame-29 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
  border:unset;
}

.student-clip .primary-BTN {
  all: unset;
  align-items: center;
  border: 2px solid;
  border-color: #a0a3bd;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.student-clip .button-text {
  all: unset;
  box-sizing: border-box;
  color: #a0a3bd;
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.student-clip .button-text-wrapper {
  all: unset;
  align-items: center;
  background-color: var(--primarycolor-1);
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.student-clip .button {
  all: unset;
  box-sizing: border-box;
  color: var(--neutral-100);
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.student-clip .left-nav-instance {
  left: 0 !important;
  position: absolute !important;
  top: 85px !important;
  height: 1200px;
}


.student-clip .left-nav-3 {
  left: unset !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  left: 8px;
  padding: 24px 0px 0px;
  position: relative;
  top: 12px;
  width: 280px;
}
