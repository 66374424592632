.create-exit-frame-settings {
  background-color: #f7f7fb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 1280px;
}

.create-exit-frame-settings .div-3 {
  background-color: #f7f7fb;
  height: 1024px;
  position: relative;
  width: 100%;
}

.create-exit-frame-settings .overlap {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.create-exit-frame-settings .rectangle {
  background-color: #ffffff;
  box-shadow: 0px 4px 24px #0000000d;
  height: 64px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.create-exit-frame-settings .header-instance {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.create-exit-frame-settings .icon-instance-node {
  height: 24px !important;
  position: absolute !important;
  width: 24px !important;
  right:0px;
}

.create-exit-frame-settings .icon-instance-node-upload {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
  /* right:0px; */
}

.create-exit-frame-settings .frame-12 {
  /* align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  height: 884px;
  left: 304px;
  overflow-y: scroll;
  position: absolute;
  top: 109px; */
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 324px;
  width: 73%;
  position: absolute;
  top: 109px;
}

.create-exit-frame-settings .frame-13 {
  /* align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;*/
  left: 0; 
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
  top: 0;
  overflow: hidden;
}

.create-exit-frame-settings .section-title {
  /* align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 1072px;
  left: 0px; */
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative
}

.create-exit-frame-settings .frame-14 {
  /* align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 636px;
   */
   align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 636px;
}

.create-exit-frame-settings .page-title {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.create-exit-frame-settings .text-wrapper-4 {
  /* color: var(--primary-text);
  font-family: "Nunito Sans", Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content; */
  color: var(--primary-text);
  font-family: 'Nunito Sans-Bold', Helvetica;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.create-exit-frame-settings .breadcrum {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.create-exit-frame-settings .material-symbols {
  height: 20px;
  position: relative;
  width: 20px;
}

.create-exit-frame-settings .keyboard-arrow-right {
  height: 8px;
  position: relative;
  width: 5.33px;
}

.create-exit-frame-settings .text-wrapper-5 {
  color: #c4c4c4;
  font-family: "Poppins", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settings .text-wrapper-6 {
  color: #4a3aff;
  font-family: "Poppins-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settings .frame-15 {
  border-radius: 0px 0px 8px 8px;
  background: #FFF;
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  position: relative;
}

.create-exit-frame-settings .frame-16 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}
.create-exit-frame-settings .design-component-instance-node-2 {
  flex: 0 0 auto !important;
}

.create-exit-frame-settings .frame-161 {
  /* align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 90%; */
  /* align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px;
  position: relative;
  min-height: 100%;
  width: 100%; */
  /* align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 100%; */


  align-items: center;
  background-color: #ffffff;
  border-radius: 24px;
  /* box-shadow: 0px 4px 16px #080f340f; */
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 32px 56px;
  width: 100%;
  position: relative;
}

.create-exit-frame-settings .frame-17 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 64px;
  position: relative;
  width: 95%;
}

.create-exit-frame-settings .frame-18 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.create-exit-frame-settings .frame-19 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.create-exit-frame-settings .frame-20 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.create-exit-frame-settings .frame-21 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.create-exit-frame-settings .text-wrapper-7 {
  color: #282828;
  font-family: "Raleway", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 35px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settings .frame-22 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  position: relative;
  left:5px;
}

.create-exit-frame-settings .frame-23 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  gap:10px;
}

.create-exit-frame-settings .component-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 10px;
  position: relative;
}

.create-exit-frame-settings .component-1 {
  background-image: url(../../../../static/img/vuesax-linear-teacher.svg) !important;
}

.create-exit-frame-settings .video-tutorial-how {
  color: transparent;
  font-family: "Raleway", Helvetica;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settings .span {
  color: #282828;
}

.create-exit-frame-settings .text-wrapper-8 {
  color: #4a3aff;
  text-decoration: underline;
}

.create-exit-frame-settings .group-2 {
   background-image: none;
   background-position: 50% 50%;
  background-size: cover;
  height: 468px;
  position: relative;
  width: 100%;
  border-radius: 20px;
}

.create-exit-frame-settings .overlap-group-wrapper {
  height: 444px;
  left: 34px;
  position: absolute;
  top: 26px;
  width: 385px;
}


.create-exit-frame-settings .group-3 {
  background-image: none;
   background-size: 100% 100%;
  height: 414px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 100%;
  border-width: 10px;
  border-style: solid;
}

.create-exit-frame-settings .frame-241 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  height: 73px;
  left: 8px;
  opacity: 0.5;
  position: relative;
  top: 170px;
}

.create-exit-frame-settings .text-wrapper-9 {
  color: #717171;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settings .fi-rr-edit-12-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 360px;
  padding: 8px;
  position: absolute;
  top: 0;
}

.create-exit-frame-settings .icon-instance-node-2 {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.create-exit-frame-settings .frame-251 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 20px;
  height: 216px;
  left: 611px;
  position: absolute;
  top: 63px;
}

.create-exit-frame-settings .frame-26 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.create-exit-frame-settings .frame-27 {
  align-items: center;
  border-color: #89939e;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
}

.create-exit-frame-settings .text-wrapper-101 {
  color: #413e3e;
  font-family: "HeadLineA-Regular" ;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settings .fi-rr-edit-14-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  justify-content: end;
  /* left: 140px; */
  padding: 8px;
  position: absolute;
  top: -13px;
  right: -12px;
}

.create-exit-frame-settings .text-wrapper-111 {
  /* color: #6e6e6e; */
  font-family: "Fraunces";
  font-size: 33px;
  /* font-weight: 700; */
  letter-spacing: 0;
  line-height: 44px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settings .fi-rr-edit-15-wrapper {
  align-items: center;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  justify-content: end;
  /* left: 204px; */
  padding: 8px;
  position: absolute;
  top: -13px;
  right: -12px;
}

.create-exit-frame-settings .overlap-21 {
  height: 80px;
  left: 630px;
  position: absolute;
  top: 347px;
  width: 388px;
}

.create-exit-frame-settings .rectangle-2 {
  border: 1px dashed;
  border-color: #89939e;
  height: 64px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 370px;
}

.create-exit-frame-settings .frame-28 {
  align-items: center;
  display: inline-flex;
  gap: 24px;
  left: 74px;
  position: absolute;
  top: 21px;
  flex-direction: row;
}

.create-exit-frame-settings .group-4 {
  height: 55px;
  position: relative;
  width: 57px;
}

.create-exit-frame-settings .overlap-group-3 {
  background-color: #6e6e6e;
  border-radius: 27.5px;
  height: 55px;
  position: relative;
  width: 55px;
}

.create-exit-frame-settings .text-wrapper-12 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 0;
  line-height: 44px;
  position: absolute;
  text-align: center;
  top: 6px;
  white-space: nowrap;
}

.create-exit-frame-settings .text-wrapper-13 {
  color: #000000;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settings .fi-rr-cloud-upload-wrapper {
  align-items: flex-start;
  background-color: #f5f5f5;
  display: inline-flex;
  gap: 10px;
  left: 352px;
  padding: 10px;
  position: absolute;
  top: 0;
}

.create-exit-frame-settings .fi-rr-edit-11-wrapper {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  /* left: 828px; */
  padding: 8px;
  position: absolute;
  top: 42px;
  right:15px;
}

.create-exit-frame-settings .template-empty {
  height: 24px !important;
  left: 0px !important;
  position: absolute !important;
  top: -3px !important;
  width: 24px !important;
}

.create-exit-frame-settings .frame-29 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
  border:unset;
}

.create-exit-frame-settings .primary-BTN {
  all: unset;
  align-items: center;
  border: 2px solid;
  border-color: #a0a3bd;
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.create-exit-frame-settings .button-text {
  all: unset;
  box-sizing: border-box;
  color: #a0a3bd;
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settings .button-text-wrapper {
  all: unset;
  align-items: center;
  background-color: var(--primarycolor-1);
  border-radius: 56px;
  box-shadow: 0px 3px 12px #4939ff2e;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 40px 21px;
  position: relative;
  cursor: pointer;
}

.create-exit-frame-settings .button {
  all: unset;
  box-sizing: border-box;
  color: var(--neutral-100);
  font-family: var(--other-18-20-b-font-family);
  font-size: var(--other-18-20-b-font-size);
  font-style: var(--other-18-20-b-font-style);
  font-weight: var(--other-18-20-b-font-weight);
  letter-spacing: var(--other-18-20-b-letter-spacing);
  line-height: var(--other-18-20-b-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settings .left-nav-instance {
  left: 0 !important;
  position: absolute !important;
  top: 85px !important;
  height: 1200px;
  
}



.create-exit-frame-settings .left-nav-3 {
  left: unset !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  left: 8px;
  padding: 24px 0px 0px;
  position: relative;
  top: 12px;
  width: 280px;
}

/* Modal Images Css */

.create-exit-frame-settingsex {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.create-exit-frame-settingsex .div-2 {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 6px 16px #0000000f;
  height: 620px;
  overflow: hidden;
  position: relative;
  width: 540px;
}

.create-exit-frame-settingsex .CTA {
  all: unset;
  align-items: center;
  background-color: #4a3aff;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  gap: 10px;
  height: 45px;
  justify-content: center;
  left: 47px;
  opacity: 0.5;
  padding: 9px 14px;
  position: absolute;
  top: 545px;
  width: 445px;
}

.create-exit-frame-settingsex .label {
  color: #ffffff;
  font-family: "Mulish", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settingsex .heading-upload {
  align-items: flex-start;
  display: inline-flex;
  gap: 10px;
  left: 42px;
  padding: 0px 10px;
  position: absolute;
  top: 30px;
}

.create-exit-frame-settingsex .text-wrapper {
  color: #0e0e0e;
  font-family: "Mulish", Helvetica;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.create-exit-frame-settingsex .upload-file-area {
  background-color: #f8f7ff;
  border: 1px dashed;
  border-color: #4a3aff;
  border-radius: 4px;
  height: 427px;
  left: 47px;
  position: absolute;
  top: 88px;
  width: 445px;
}

.create-exit-frame-settingsex .frame-2 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 28px;
  left: 62px;
  position: relative;
  top: 142px;
}

.create-exit-frame-settingsex .upload-icon {
  height: 59.89px;
  position: relative;
  width: 68.78px;
}

.create-exit-frame-settingsex .frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.create-exit-frame-settingsex .drag-drop-files {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 5px;
  position: relative;
}

.create-exit-frame-settingsex .drag-drop-files-or {
  color: transparent;
  font-family: "Mulish", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.create-exit-frame-settingsex .span {
  color: #0e0e0e;
}

.create-exit-frame-settingsex .text-wrapper-2 {
  color: #333333;
}

.create-exit-frame-settingsex .text-wrapper-3 {
  color: #4a3aff;
  text-decoration: underline;
}

.create-exit-frame-settingsex .frame-1027 {
  flex: 0 0 auto !important;
  padding: 0px 0px 0px 6px !important;
}
/* Modal Images Css  */
.plusIcon_Image{
  align-items: center;
  display: flex;
  border: 1px solid gray;
  width: 60px;
  justify-content: center;
}
.edit-icon-backgroundcolor{
  top: -10px;
  position: absolute;
  right: 10px;
  background-color: black;
  padding: 5px;
}


.create-exit-frame-settings .exit-clip-root {
  margin-top: 10px;
  background-color: rgb(0, 0, 0);
  border: solid 1px;
  padding: 175px;
  color: #fff;
  height: 480px;
  width: 960px;
  position: relative;
  border-radius: 20px;
}

.create-exit-frame-settings .exit-clip-root .exit-clip-upload-logo {
      width: 750px;
      height: 290px;
      border: dotted 1px #fff;
      margin-top: -318px;
      cursor: pointer;
  }
  .create-exit-frame-settings .empty-logo-text{
    font-family: "DM Sans", Helvetica;
    margin-top: 10px;
  }
  .create-exit-frame-settings .exit-clip-root .parent {
      display: flex;
      justify-content: center; /* horizontally center */
      align-items: center; /* vertically center */
      height: 453px; /* adjust height as needed */
      /*border: 1px solid black;*/ /* for visualization */
  }

  .create-exit-frame-settings .exit-clip-root .logo-text {
      width: 750px;
      /* height: 64px; */
      border: 1px dotted #fff;
      top: 404px;
      left: 245px;
      color: #fff;
      text-align: center;
      margin-top: 5px;
  }

  .create-exit-frame-settings .exit-clip-root .logo-text .edit-icon {
        right: -97%;
          background-color: black;
          top: -1px !important;
      }

      .create-exit-frame-settings .exit-clip-root .logo-text .edit-icon, .exit-clip-upload-logo .upload-icon {
          position: relative;
          color: #fff;
          top: -8px;
          width:24px;
      }

      .create-exit-frame-settings .exit-clip-upload-logo .upload-icon {
  right: -97%;
  background-color: black;
  padding: 5px;
}

.exit-clip-upload-logo img {
  max-width: 100%; /* Ensure image doesn't exceed container width */
  max-height: 100%; /* Ensure image doesn't exceed container height */
  object-fit: contain; /* Maintain aspect ratio and fit within container */
}


.create-exit-frame-settings .exit-clip-root .child {
  /* Additional styling for the child */
  position: relative;
}

.create-exit-frame-settings .exit-clip-root .logo-container {
  width: 100px;
  height: 100px;
  border-radius: 50%; /* makes the container circular */
  /*overflow: hidden;*/ /* clips the image to the circular shape */
  border: 2px solid #000; /* border for visualization, you can remove this */
  top: 100px;
  color: #fff;
  /*position: relative;*/
  margin-top: -150px;
  margin-left:14px;
}

.create-exit-frame-settings .exit-clip-root .logo {
  width: 100%; /* make the image fill the circular container */
  height: auto; /* maintain aspect ratio */
}

.create-exit-frame-settings .edit-icon-background{
top: 50px;
  position: absolute;
  right: -5px;
  background-color: black;
  padding: 5px;
}